<!-- <app-navbar></app-navbar> -->
<div class="tw-flex tw-flex-col tw-items-center tw-mb-12 tw-mt-2">
    <div *ngIf="tenantSettings?.showWeatherMap">
        <iframe
            src="https://www.meteoblue.com/en/weather/widget/three/altoona_united-states_5178195?geoloc=detect&nocurrent=0&noforecast=0&days=4&tempunit=FAHRENHEIT&windunit=MILE_PER_HOUR&layout=image"
            frameborder="1"
            scrolling="NO"
            allowtransparency="true"
            sandbox="allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox"
            width="1000"
            height="593"
        ></iframe>
    </div>

    <div class="comic" *ngIf="tenantSettings?.showComic">
        <iframe
            scrolling="auto"
            src="https://xkcd.com/#comic"
            class="tw-w-[1000px] tw-h-[500px] tw-mt-7"
        ></iframe>
    </div>

    <div *ngIf="tenantSettings?.showRssFeed">
        <h3 class="tw-m-12 tw-mt-6 tw-text-4xl">Blog | daily.dev</h3>
        <iframe
            width="900"
            height="1600"
            src="https://rss.app/embed/v1/wall/QrSxRJHTP54Uggm9"
            frameborder="0"
        ></iframe>
    </div>
</div>
