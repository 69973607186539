<app-dialog-header [title]="'Add Time Off'"></app-dialog-header>

<mat-divider class="divider"></mat-divider>

<div mat-dialog-content class="create-time-off-container">
    <form [formGroup]="timeOffForm">
        <mat-form-field appearance="outline" class="tw-w-full">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
                [formGroup]="timeOffForm"
                [rangePicker]="picker"
            >
                <input
                    matStartDate
                    formControlName="start"
                    placeholder="Start date"
                />
                <input
                    matEndDate
                    formControlName="end"
                    placeholder="End date"
                />
            </mat-date-range-input>
            <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle
                matIconSuffix
                [for]="picker"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error
                *ngIf="
                    timeOffForm.controls.start.hasError('matStartDateInvalid')
                "
            >
                Invalid start date
            </mat-error>
            <mat-error
                *ngIf="timeOffForm.controls.end.hasError('matEndDateInvalid')"
            >
                Invalid end date
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" class="tw-w-full tw-mt-5">
            <mat-label>Description</mat-label>
            <input
                matInput
                formControlName="description"
                placeholder="Description"
                maxlength="60"
            />
        </mat-form-field>
    </form>
</div>

<app-dialog-footer
    [disabled]="saving"
    [posButtonText]="'Add Time Off'"
    (buttonClick)="saveTimeOff()"
    (closeClick)="closeDialog()"
></app-dialog-footer>
