<mat-card class="tw-gap-2">
    <mat-card-header class="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <mat-card-title>Edit Setting</mat-card-title>
        <button mat-icon-button (click)="onFormClose()">
            <mat-icon class="tw-m-0 m3-error">close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <form
            class="tw-flex tw-flex-col tw-items-center tw-gap-2"
            [formGroup]="settingForm"
        >
            <p class="warning" *ngIf="failed">Failed to edit settings.</p>

            <mat-form-field class="tw-w-full" appearance="fill">
                <mat-label>Title</mat-label>
                <input [readonly]="!setting" matInput formControlName="name" />
            </mat-form-field>

            <!-- Day of Week -->
            <mat-form-field
                class="tw-w-full"
                *ngIf="setting && setting.name === SettingEnum.StartDayOfWeek"
                appearance="fill"
            >
                <mat-label>Value</mat-label>

                <mat-select formControlName="value">
                    <mat-option
                        *ngFor="let weekday of daysOfWeek"
                        [value]="weekday"
                    >
                        {{ weekday }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <!-- Employee Type -->

            <mat-form-field
                class="tw-w-full"
                *ngIf="
                    setting && setting.name === SettingEnum.DefaultEmployeeType
                "
                appearance="fill"
            >
                <mat-label>Value</mat-label>

                <mat-select formControlName="value">
                    <ng-container *ngFor="let employeeType of employeeTypes">
                        <mat-option
                            *ngIf="
                                employeeType.isActive ||
                                employeeType.id ===
                                    settingForm.controls.value.value
                            "
                            [value]="employeeType.id"
                        >
                            {{ employeeType.name }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <mat-form-field
                class="tw-w-full"
                *ngIf="setting && setting.name === SettingEnum.TimesheetAdmin"
                appearance="fill"
            >
                <mat-label>User</mat-label>

                <mat-select formControlName="value">
                    <ng-container *ngFor="let user of users">
                        <mat-option [value]="user.id">
                            {{ user.firstName + " " + user.lastName }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>

            <!-- Default Email Subject -->
            <mat-form-field
                class="tw-w-full"
                *ngIf="setting && getIfEmailSetting(setting.name)"
                appearance="fill"
            >
                <mat-label>Subject</mat-label>
                <input matInput formControlName="subject" maxlength="200" />
            </mat-form-field>

            <!-- Default Email Body -->
            <mat-form-field
                class="tw-w-full"
                *ngIf="setting && getIfEmailSetting(setting.name)"
                appearance="fill"
            >
                <mat-label>Body</mat-label>
                <textarea
                    matInput
                    formControlName="body"
                    rows="5"
                    maxlength="1800"
                ></textarea>
                <mat-hint align="start">Max 1800 characters</mat-hint>
            </mat-form-field>

            <!-- Work Order Notes Configuration -->
            <div
                class="tw-flex tw-flex-row tw-gap-2 tw-w-full"
                *ngIf="
                    setting &&
                    setting.name.toLowerCase() ===
                        SettingEnum.WorkOrderNotesConfig.toLowerCase()
                "
            >
                <mat-form-field class="tw-w-full" appearance="fill">
                    <mat-label>Subject</mat-label>
                    <input matInput formControlName="subject" maxlength="200" />
                    <button
                        mat-button
                        matSuffix
                        (click)="openDialog(this.selectedWorkOrderNote.id)"
                    >
                        <mat-icon>close</mat-icon>
                    </button>
                    <mat-hint align="start">Max 200 characters</mat-hint>
                </mat-form-field>

                <mat-form-field>
                    <mat-select
                        placeholder="Select a note"
                        (selectionChange)="onSelectionChange($event)"
                    >
                        <mat-option value="New">New</mat-option>
                        <mat-option
                            *ngFor="let note of workOrderNotes"
                            [value]="note"
                        >
                            {{ note.subject }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-form-field
                class="tw-w-full"
                *ngIf="
                    setting &&
                    setting.name.toLowerCase() ===
                        SettingEnum.WorkOrderNotesConfig.toLowerCase()
                "
                appearance="fill"
            >
                <mat-label>Body</mat-label>
                <textarea
                    matInput
                    formControlName="body"
                    rows="5"
                    maxlength="1800"
                ></textarea>
                <mat-hint align="start">Max 1800 characters</mat-hint>
            </mat-form-field>

            <div class="tw-flex tw-justify-between tw-w-full">
                <button
                    *ngIf="setting"
                    [disabled]="!setting || loading"
                    mat-raised-button
                    class="m3-error"
                    (click)="initializeForm(setting, selectedWorkOrderNote)"
                >
                    Reset
                </button>
                <button
                    *ngIf="!newWorkOrder"
                    [disabled]="!setting || loading"
                    mat-raised-button
                    (click)="onFormSave()"
                >
                    Save Changes
                </button>

                <button
                    *ngIf="newWorkOrder"
                    [disabled]="!setting || loading"
                    mat-raised-button
                    (click)="onCreateClick()"
                >
                    Create New Note
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
