import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
    MAT_DATE_RANGE_SELECTION_STRATEGY,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import {
    addWeeks,
    endOfISOWeek,
    getISOWeek,
    getISOWeekYear,
    startOfISOWeek,
    differenceInCalendarWeeks,
} from 'date-fns';
import { ITimesheetDateFormControls } from 'src/app/interfaces/timesheets';
import { ThemeService } from 'src/app/services/theme.service';
import { TimesheetDate } from 'src/app/models/timesheet-date-info.model';
import { Task } from 'src/app/models/task.model';
import { WeekHours } from 'src/app/models/week-hours.model';
import { ISOWeekSelectionStrategy } from 'src/app/strategies/iso-week-selection.strategy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';

@Component({
    selector: 'app-timesheet-controls',
    templateUrl: './timesheet-controls.component.html',
    styleUrls: ['./timesheet-controls.component.scss'],
    providers: [
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: ISOWeekSelectionStrategy,
        },
    ],
    standalone: true,
    imports: [
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatDividerModule,
        MatCardModule,
        MatListModule,
    ],
})
export class TimesheetControlsComponent implements OnInit {
    @Input() activeTasks: Task[];

    @Input() weekHours: WeekHours;

    @Output() updateDateEvent = new EventEmitter<TimesheetDate>();
    @Output() submitTimesheetsEvent = new EventEmitter();
    @Output() saveTimesheetsEvent = new EventEmitter();
    @Output() editTaskEvent = new EventEmitter();

    dateFormGroup = new FormGroup<ITimesheetDateFormControls>({
        beginningOfWeek: new FormControl<Date>(startOfISOWeek(new Date())),
        endOfWeek: new FormControl<Date>(endOfISOWeek(new Date())),
        selectedWeek: new FormControl<number>(null),
        selectedYear: new FormControl<number>(null),
    });

    weekIdentifier: string;

    ngOnInit(): void {
        this.updateDate();

        this.dateFormGroup.controls.beginningOfWeek.valueChanges
            .pipe(debounceTime(200), distinctUntilChanged())
            .subscribe(() => this.updateDate());
    }

    constructor(private themeService: ThemeService) {}

    private setWeekAndYear() {
        this.dateFormGroup.controls.selectedYear.setValue(
            getISOWeekYear(this.dateFormGroup.controls.beginningOfWeek.value),
        );
        this.dateFormGroup.controls.selectedWeek.setValue(
            getISOWeek(this.dateFormGroup.controls.beginningOfWeek.value),
        );
    }

    updateDate(): void {
        const numberOfWeeksFromNow = differenceInCalendarWeeks(
            new Date(),
            this.dateFormGroup.controls.beginningOfWeek.value,
            {
                weekStartsOn: 1,
            },
        );

        if (numberOfWeeksFromNow == 0) {
            this.weekIdentifier = 'This Week';
        } else if (numberOfWeeksFromNow == -1) {
            this.weekIdentifier = 'Next Week';
        } else if (numberOfWeeksFromNow == 1) {
            this.weekIdentifier = 'Last Week';
        } else if (numberOfWeeksFromNow < 0) {
            this.weekIdentifier = `${Math.abs(numberOfWeeksFromNow)} Weeks From Now`;
        } else if (numberOfWeeksFromNow > 0) {
            this.weekIdentifier = `${Math.abs(numberOfWeeksFromNow)} Weeks Ago`;
        }

        this.setWeekAndYear();
        this.updateDateEvent.emit(
            TimesheetDate.fromJson(this.dateFormGroup.getRawValue()),
        );
    }

    onClickNextWeek(): void {
        this.dateFormGroup.controls.endOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.endOfWeek.value, 1),
        );
        this.dateFormGroup.controls.beginningOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.beginningOfWeek.value, 1),
        );
    }

    onClickPreviousWeek(): void {
        this.dateFormGroup.controls.endOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.endOfWeek.value, -1),
        );
        this.dateFormGroup.controls.beginningOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.beginningOfWeek.value, -1),
        );
    }
}
