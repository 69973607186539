<div class="tw-mt-4 tw-grid tw-w-full tw-grid-col tw-gap-2">
    <div class="tw-flex tw-justify-center">
        <mat-card class="tw-flex md:tw-w-1/2 tw-w-4/5">
            <mat-card-header>
                <mat-card-title>Contact Info</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form
                    [formGroup]="userContactInfoForm"
                    (ngSubmit)="userContactInfoSave()"
                    id="contact-info"
                    class="tw-grid md:tw-grid-cols-6 tw-gap-2"
                >
                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-4"
                    >
                        <mat-label>Address</mat-label>
                        <input
                            matInput
                            formControlName="address"
                            maxlength="50"
                            required
                        />
                        <mat-error
                            *ngIf="
                                userContactInfoForm.controls.address.hasError(
                                    'maxLength'
                                )
                            "
                        >
                            Must be shorter than 50 characters
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-2"
                    >
                        <mat-label>City</mat-label>
                        <input
                            matInput
                            formControlName="city"
                            maxlength="20"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>State</mat-label>
                        <input matInput formControlName="state" required />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Zip Code</mat-label>
                        <input
                            matInput
                            formControlName="zipCode"
                            maxlength="5"
                            minlength="5"
                            required
                        />
                        <mat-error
                            *ngIf="
                                userContactInfoForm.controls.zipCode.hasError(
                                    'minLength'
                                )
                            "
                        >
                            Must be at least 5 characters
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Country</mat-label>
                        <input
                            matInput
                            formControlName="country"
                            maxlength="56"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Primary Phone Number</mat-label>
                        <input
                            matInput
                            formControlName="primaryPhoneNumber"
                            placeholder="000-000-0000"
                            maxlength="12"
                            required
                        />
                        <mat-error
                            *ngIf="
                                userContactInfoForm.controls.primaryPhoneNumber
                                    .invalid
                            "
                        >
                            Must be in 000-000-0000 format
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Secondary Phone Number</mat-label>
                        <input
                            matInput
                            formControlName="secondaryPhoneNumber"
                            maxlength="12"
                            placeholder="000-000-0000"
                        />
                        <mat-error
                            *ngIf="
                                userContactInfoForm.controls
                                    .secondaryPhoneNumber.invalid
                            "
                        >
                            Must be in 000-000-0000 format
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Personal Email Address</mat-label>
                        <input matInput formControlName="personalEmail" />
                        <mat-error
                            *ngIf="
                                userContactInfoForm.controls.personalEmail
                                    .invalid
                            "
                        >
                            Improper email format
                        </mat-error>
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-card-actions>
                <button
                    [disabled]="userContactInfoForm.invalid"
                    mat-raised-button
                    class="tw-flex tw-justify-end"
                    type="submit"
                >
                    Confirm Changes
                </button>
            </mat-card-actions>
        </mat-card>
    </div>

    <div class="tw-flex tw-justify-center">
        <mat-card class="tw-flex md:tw-w-1/2 tw-w-4/5">
            <mat-card-header>
                <mat-card-title>Emergency Contact</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <form
                    [formGroup]="userEmergencyContactForm"
                    (ngSubmit)="emergencyContactSaved()"
                    class="tw-grid md:tw-grid-cols-6 tw-gap-2"
                >
                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>First Name</mat-label>
                        <input
                            matInput
                            formControlName="firstName"
                            maxlength="50"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Last Name</mat-label>
                        <input
                            matInput
                            formControlName="lastName"
                            maxlength="50"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-4"
                    >
                        <mat-label>Address</mat-label>
                        <input
                            matInput
                            formControlName="address"
                            maxlength="50"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-2"
                    >
                        <mat-label>City</mat-label>
                        <input
                            matInput
                            formControlName="city"
                            maxlength="20"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-2"
                    >
                        <mat-label>State</mat-label>
                        <input matInput formControlName="state" required />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-2"
                    >
                        <mat-label>Zip Code</mat-label>
                        <input
                            matInput
                            formControlName="zipCode"
                            maxlength="5"
                            minlength="5"
                            required
                        />
                        <mat-error
                            *ngIf="
                                userEmergencyContactForm.controls.zipCode.hasError(
                                    'minLength'
                                )
                            "
                        >
                            Must be at least 5 characters
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-2"
                    >
                        <mat-label>Country</mat-label>
                        <input
                            matInput
                            formControlName="country"
                            maxlength="50"
                            required
                        />
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Primary Phone Number</mat-label>
                        <input
                            matInput
                            formControlName="primaryPhoneNumber"
                            placeholder="000-000-0000"
                            maxlength="12"
                            required
                        />
                        <mat-error
                            *ngIf="
                                userContactInfoForm.controls.primaryPhoneNumber
                                    .invalid
                            "
                        >
                            Must be in 000-000-0000 format
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Secondary Phone Number</mat-label>
                        <input
                            matInput
                            formControlName="secondaryPhoneNumber"
                            maxlength="12"
                            placeholder="000-000-0000"
                        />
                        <mat-error
                            *ngIf="
                                userEmergencyContactForm.controls
                                    .secondaryPhoneNumber.invalid
                            "
                        >
                            Must be in 000-000-0000 format
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field
                        appearance="outline"
                        class="md:tw-col-span-3"
                    >
                        <mat-label>Relationship</mat-label>
                        <input
                            matInput
                            formControlName="relationship"
                            maxlength="20"
                            required
                        />
                    </mat-form-field>
                </form>
            </mat-card-content>
            <mat-card-actions>
                <button
                    class="btn-submit"
                    [disabled]="userEmergencyContactForm.invalid"
                    mat-raised-button
                    type="submit"
                >
                    Confirm Changes
                </button>
            </mat-card-actions>
        </mat-card>
    </div>
</div>
