import { Component, Inject, OnInit } from '@angular/core';
import {
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialogRef,
} from '@angular/material/dialog';
import {
    AbstractControl,
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { DocumentService } from 'src/app/services/document.service';
import { DialogFooterComponent } from '../../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { DialogHeaderComponent } from '../../dialog-header/dialog-header.component';
import { VisibleDirective } from '../../../directives/visible.directive';

interface DialogData {
    name: string;
}

interface IDocumentFormControls {
    docName: FormControl<string | null>;
    file: FormControl<File | null>;
    extension: FormControl<string | null>;
    fileLocation: FormControl<string | null>;
    status: FormControl<boolean>;
}

@Component({
    selector: 'app-add-document',
    templateUrl: './add-document.component.html',
    styleUrls: ['./add-document.component.scss'],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatChipsModule,
        MatIconModule,
        MatButtonModule,
        DialogFooterComponent,
        VisibleDirective,
    ],
})
export class AddDocumentComponent implements OnInit {
    documentForm: FormGroup<IDocumentFormControls> = new FormGroup({
        docName: new FormControl(null, [Validators.required.bind(this)]),
        file: new FormControl(null, [
            Validators.required.bind(this),
            (control: AbstractControl<File>): ValidationErrors => {
                let valid = true;
                if (control.value != null) {
                    valid = false;
                    for (const ext of [
                        '.pdf',
                        '.doc',
                        '.docx',
                        '.png',
                        '.jpg',
                        '.jpeg',
                    ]) {
                        if (control.value.name.includes(ext)) {
                            valid = true;
                        }
                    }
                }
                return valid
                    ? null
                    : {
                          fileType: !valid,
                      };
            },
        ]),
        extension: new FormControl(null),
        fileLocation: new FormControl(null),
        status: new FormControl(true),
    });

    saveFailed = false;
    saving = false;
    nonAcceptedFileType = false;
    uploadServerError = false;
    uploadServerMessage = 'Upload Failed';
    nodeData: DialogData;

    get file(): FormControl<File | null> {
        return this.documentForm.controls.file;
    }

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private dialogRef: MatDialogRef<boolean>,
        private documentService: DocumentService,
    ) {}

    ngOnInit(): void {
        this.nodeData = this.data;
    }

    onDragOver(e: DragEvent) {
        e.preventDefault();
    }

    onFileDrop(e: DragEvent) {
        e.preventDefault();
        e.stopPropagation();
        this.documentForm.controls.file.setValue(e.dataTransfer.files.item(0));
        this.documentForm.controls.file.markAsDirty();
    }

    onFileSelected(e: Event) {
        this.documentForm.controls.file.setValue(
            (e.target as HTMLInputElement).files.item(0),
        );
        this.documentForm.controls.file.markAsDirty();
    }

    onResetClick(): void {
        this.documentForm.reset();
    }

    onCloseClick(): void {
        this.dialogRef.close(false);
    }

    onRemove(): void {
        this.documentForm.controls.file.setValue(null);
    }

    uploadFile(): void {
        this.documentService
            .uploadFile(
                this.documentForm.controls.file.value,
                this.documentForm.controls.docName.value,
                this.nodeData.name,
            )
            .subscribe({
                next: () => {
                    this.dialogRef.close(true);
                },
                error: (error) => {
                    this.uploadServerError = true;
                    this.uploadServerMessage = error as string;
                },
            });
    }

    onSaveClick(): void {
        this.documentForm.markAllAsTouched();
        if (
            !this.documentForm.valid ||
            this.documentForm.controls.file.value == null
        ) {
            return;
        }
        this.saving = true;
        this.uploadFile();
    }
}
