import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    Output,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
    CalendarView,
    CalendarEvent,
    CalendarMonthViewBeforeRenderEvent,
    CalendarWeekViewBeforeRenderEvent,
    CalendarCommonModule,
    CalendarMonthModule,
} from 'angular-calendar';
import { ViewPeriod } from 'calendar-utils';
import { RRule, Weekday, Frequency } from 'rrule';
import { Subject } from 'rxjs';
import { DayDialogComponent } from './day-dialog/day-dialog.component';
import { TimeOffManagerDialogComponent } from './add-time-off-dialog/time-off-mananger-dialog.component';
import { TimeOffService } from 'src/app/services/time-off.service';
import { TempTimeOff } from 'src/app/models/TimeOff-Temp.model';
import { getHolidays, Holidays } from 'date-fns-holiday-us';
import { DatePipe, NgIf, NgFor } from '@angular/common';
import { DayOfWeekAsString } from 'src/app/enums/day-of-week.enum.ts';
import { MatButtonModule } from '@angular/material/button';
import { endOfDay, startOfDay } from 'date-fns';
import { MatCardModule } from '@angular/material/card';

interface RecurringEvent {
    title: string;
    start: Date;
    rrule?: {
        freq: Frequency;
        bymonth?: number;
        bymonthday?: number;
        byweekday?: Weekday;
        interval: number;
    };
}

enum Month {
    JANUARY,
    FEBRUARY,
    MARCH,
    APRIL,
    MAY,
    JUNE,
    JULY,
    AUGUST,
    SEPTEMBER,
    OCTOBER,
    NOVEMBER,
    DECEMBER,
}

@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss'],
    providers: [DatePipe],
    standalone: true,
    imports: [
        MatButtonModule,
        CalendarCommonModule,
        CalendarMonthModule,
        NgIf,
        NgFor,
        MatCardModule,
    ],
})
export class CalendarComponent {
    @Input() parentDate: Date;

    @Output() dateChanged = new EventEmitter<Date>();

    view: CalendarView = CalendarView.Month;
    CalendarView = CalendarView;
    viewDate: Date = new Date();
    refresh = new Subject<void>();
    activeDayIsOpen = false;
    viewRender:
        | CalendarMonthViewBeforeRenderEvent
        | CalendarWeekViewBeforeRenderEvent;

    allHolidays: Holidays;
    calendarEvents: CalendarEvent[];

    psHolidays = [
        {
            format: 'newYearsDay',
            title: 'PSS HOLIDAY - New Years Day',
            start: new Date(),
        },
        {
            format: 'memorialDay',
            title: 'PSS HOLIDAY - Memorial Day',
            start: new Date(),
        },
        {
            format: 'independenceDay',
            title: 'PSS HOLIDAY - Fourth of July',
            start: new Date(),
        },
        {
            format: 'laborDay',
            title: 'PSS HOLIDAY - Labor Day',
            start: new Date(),
        },
        {
            format: 'thanksgiving',
            title: 'PSS HOLIDAY - Thanksgiving',
            start: new Date(),
        },
        {
            format: 'christmas',
            title: 'PSS HOLIDAY - Christmas',
            start: new Date(),
        },
    ];

    recurringEvents: RecurringEvent[] = [
        {
            title: 'PAY DAY',
            start: new Date(2023, Month.JANUARY, 6),
            rrule: {
                freq: RRule.WEEKLY,
                byweekday: RRule.FR,
                interval: 2,
            },
        },
    ];

    viewPeriod: ViewPeriod;
    eventLimit = 3;
    showDayPopup = false;

    constructor(
        private cdr: ChangeDetectorRef,
        public dialog: MatDialog,
        private timeOffService: TimeOffService,
        private datePipe: DatePipe,
    ) {}

    getTimeOff(): void {
        this.calendarEvents = [];
        const queryParams = new Map<string, string>();
        queryParams.set('currentDate', this.viewDate.toDateString());

        this.timeOffService
            .get([], queryParams)
            .subscribe((timeOffs: TempTimeOff[]) => {
                timeOffs.forEach((timeOff) => {
                    const calendarEvent: CalendarEvent = {
                        start: new Date(timeOff.startDate),
                        end: new Date(timeOff.endDate),
                        title:
                            timeOff.userName + '-' + timeOff.timeOffDescription,
                        id: timeOff.id,
                    };

                    const index = this.calendarEvents.findIndex(
                        (currentEvent) => calendarEvent.id === currentEvent.id,
                    );

                    if (index === -1) {
                        this.calendarEvents.push(calendarEvent);
                    }
                });
                this.refresh.next();
            });
    }

    getHolidays(): void {
        this.allHolidays = getHolidays(this.viewDate.getFullYear());

        for (const holiday of this.psHolidays) {
            if (this.allHolidays[holiday.format]) {
                const date = this.allHolidays[holiday.format].date;
                const day = this.datePipe
                    .transform(this.allHolidays[holiday.format].date, 'EEEE')
                    .toUpperCase();

                if (day === DayOfWeekAsString.SUNDAY) {
                    date.setDate(date.getDate() + 1);
                } else if (day === DayOfWeekAsString.SATURDAY) {
                    date.setDate(date.getDate() - 1);
                }

                holiday.start = date;
            }
        }
    }

    updateCalendarEvents(
        viewRender:
            | CalendarMonthViewBeforeRenderEvent
            | CalendarWeekViewBeforeRenderEvent,
        forceRefresh = false,
    ): void {
        this.viewRender = viewRender;
        if (!this.viewPeriod || forceRefresh) {
            this.viewRender = viewRender;
            this.viewPeriod = viewRender.period;
            this.calendarEvents = [];
            this.getTimeOff();
            this.getHolidays();

            this.recurringEvents.forEach((event) => {
                const periodEnd = new Date(viewRender.period.end);
                periodEnd.setMonth(periodEnd.getMonth() + 1);

                const rule: RRule = new RRule({
                    ...event.rrule,
                    dtstart: startOfDay(event.start),
                    until: endOfDay(periodEnd),
                });

                const { title } = event;

                rule.all().forEach((date) => {
                    this.calendarEvents.push({
                        title,
                        start: new Date(date),
                    });
                });
            });

            this.psHolidays.forEach((event) => {
                this.calendarEvents.push(event);
            });

            this.closeOpenMonthViewDay();
            this.cdr.detectChanges();
        }
    }

    dayClicked(event: { events: CalendarEvent[]; date: Date }): void {
        if (event.events.length > 0) {
            this.dialog.open(DayDialogComponent, {
                data: { dayEvents: event.events, date: event.date },
                width: '500px',
                height: '500px',
            });
        }
    }

    setView(view: CalendarView): void {
        this.view = view;
    }

    closeOpenMonthViewDay(): void {
        this.activeDayIsOpen = false;
    }

    openTimeOffManager(): void {
        const dialogRef = this.dialog.open(TimeOffManagerDialogComponent, {
            width: '600px',
            height: '600px',
            autoFocus: false,
        });

        dialogRef.afterClosed().subscribe(() => {
            this.updateCalendarEvents(this.viewRender, true);
        });
    }
}
