<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingSettings"
></mat-progress-bar>

<div
    class="tw-grid tw-grid-flow-row md:tw-grid-cols-10 tw-items-start tw-gap-4 tw-m-4"
>
    <mat-card
        class="tw-flex tw-flex-col tw-gap-2 tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-3 lg:tw-col-start-2 2xl:tw-col-start-2 tw-min-w-fit"
    >
        <mat-card-content class="tw-grid tw-grid-flow-row tw-gap-2">
            <div [formGroup]="searchForm">
                <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
                    <mat-label>Search Settings</mat-label>
                    <input matInput type="text" formControlName="search" />
                </mat-form-field>
            </div>

            <mat-selection-list
                #settingSelectionList
                hideSingleSelectionIndicator
                class="scrollable"
                [multiple]="false"
                (selectionChange)="settingSelectionChange($event)"
            >
                @for (setting of page.items; track setting) {
                    <mat-list-option [value]="setting">
                        {{ setting.name }}
                    </mat-list-option>
                    @if (!$last) {
                        <mat-divider></mat-divider>
                    }
                }
            </mat-selection-list>
        </mat-card-content>
        <mat-card-actions class="tw-ml-auto">
            <mat-paginator
                class="tw-bg-transparent"
                [pageIndex]="pageIndex"
                [pageSize]="page.countRequested"
                [length]="page.totalCount"
                [hidePageSize]="true"
                (page)="changePage($event)"
            ></mat-paginator>
        </mat-card-actions>
    </mat-card>

    <div class="tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-5 tw-min-w-fit">
        <app-edit-setting
            [hidden]="!settingSelected"
            (settingChangedEvent)="settingChanged($event)"
        ></app-edit-setting>
    </div>
</div>
