import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import {
    MatPaginator,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { Project } from 'src/app/models/project-model';
import { ClientService } from 'src/app/services/client.service';
import { ProjectsService } from 'src/app/services/projects.service';
import { AddProjectComponent } from './add-project/add-project.component';
import { EditProjectComponent } from './edit-project/edit-project.component';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgFor, NgIf } from '@angular/common';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { AppStateService } from '../../services/app-state-service';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatSlideToggleModule,
        NgIf,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        MatProgressBarModule,
        MatTooltipModule,
        MatInputModule,
        MatListModule,
        MatPaginatorModule,
        EditProjectComponent,
        VisibleDirective,
        MatCardModule,
        MatIconModule,
    ],
})
export class ProjectsComponent implements OnInit {
    @ViewChild(EditProjectComponent)
    editProjectComponent!: EditProjectComponent;
    @ViewChild(MatPaginator) paginator!: MatPaginator;

    clients: Client[] = [];
    projectSelected = false;
    projectStatus = true;
    loadingProjects = false;
    searchForm = new FormGroup({
        search: new FormControl<string>(null),
        clientId: new FormControl<string>(null),
    });
    canViewClient: boolean = false;
    page = new Page<Project>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    pageIndex = 0;
    queryParams = new Map();

    constructor(
        private projectService: ProjectsService,
        private clientService: ClientService,
        private dialogService: MatDialog,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private appStateService: AppStateService,
    ) {}

    ngOnInit(): void {
        const queryParamClientId =
            this.activatedRoute.snapshot.queryParamMap.get('clientId');

        this.searchForm.controls.clientId.valueChanges.subscribe(() => {
            if (this.editProjectComponent) {
                this.editProjectComponent.project = null;
                this.editProjectComponent.initializeForm();
            }

            this.setQueryParams();
            this.changePageIndex(0);
        });

        this.queryParams.set('count', 1000);
        this.clientService
            .get([], this.queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;

                if (queryParamClientId) {
                    this.searchForm.controls.clientId.setValue(
                        queryParamClientId,
                    );
                } else {
                    this.searchForm.controls.clientId.setValue(
                        this.clients[0].id,
                    );
                }
            });

        this.searchForm.controls.search.valueChanges.subscribe(() => {
            if (this.paginator) {
                this.paginator.firstPage();
            }

            this.changePageIndex(0);
        });

        this.queryParams.delete('count');
    }

    toggleActiveInactive(): void {
        this.projectStatus = !this.projectStatus;
        this.changePageIndex(0);
    }

    onClientRoute(clientId: string): void {
        this.appStateService.title$.next('Clients');
        void this.router.navigate(['clients'], {
            queryParams: { clientId: clientId },
        });
    }

    clientChange() {
        this.projectSelected = false;
    }

    projectChange(event: Project | null): void {
        if (event === null) {
            this.projectSelected = false;
        }
    }

    openAddDialog(): void {
        const map = new Map();
        map.set('status', ACTIVE);
        map.set('count', 100);
        this.clientService.get([], map).subscribe(() => {
            const dialogRef = this.dialogService.open(AddProjectComponent, {
                disableClose: true,
                data: this.searchForm.controls.clientId.value,
                width: '25%',
            });

            dialogRef.afterClosed().subscribe((added) => {
                if (added) {
                    this.updateProjects();
                }
            });
        });
    }

    onLoadingProjectEdit(value: boolean): void {
        if (!value) {
            this.loadingProjects = false;
            this.updateProjects();
        } else {
            this.loadingProjects = true;
        }
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.updateProjects();
    }

    setQueryParams(): void {
        this.queryParams.set('count', 8);

        if (this.projectStatus) {
            this.queryParams.set('status', ACTIVE);
        } else {
            this.queryParams.set('status', INACTIVE);
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 8);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (
            this.searchForm.controls.search.value &&
            this.searchForm.controls.search.value !== ''
        ) {
            this.queryParams.set('name', this.searchForm.controls.search.value);
        }

        if (this.searchForm.controls.search.value === '') {
            if (this.queryParams.has('name')) {
                this.queryParams.delete('name');
            }
        }

        if (this.searchForm.controls.clientId.value) {
            this.queryParams.set(
                'clientId',
                this.searchForm.controls.clientId.value,
            );
        }
    }

    updateProjects(): void {
        this.setQueryParams();
        this.projectService
            .get([], this.queryParams)
            .subscribe((response: Page<Project>) => {
                this.page = response;
            });
    }

    projectSelectionChange(event: MatSelectionListChange): void {
        this.editProjectComponent.editingTasks = false;
        if (!this.projectSelected) {
            this.projectSelected = true;
        }

        const map = new Map();
        map.set('status', ACTIVE);
        map.set('count', 100);
        this.clientService.get([], map).subscribe(() => {
            this.editProjectComponent.setEditProject(event.options[0].value);
        });
    }
}
