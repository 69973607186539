<mat-card>
    <mat-card-content
        class="tw-grid tw-grid-cols-8 lg:tw-grid-cols-10 tw-text-center tw-h-fit tw-items-center tw-justify-items-center tw-gap-2"
    >
        <div class="lg:tw-col-span-2 tw-hidden lg:tw-flex"></div>

        <div>
            Monday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.mondayTotal.value }}
        </div>

        <div>
            Tuesday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.tuesdayTotal.value }}
        </div>

        <div>
            Wednesday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.wednesdayTotal.value }}
        </div>

        <div>
            Thursday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.thursdayTotal.value }}
        </div>

        <div>
            Friday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.fridayTotal.value }}
        </div>

        <div>
            Saturday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.saturdayTotal.value }}
        </div>

        <div>
            Sunday
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.sundayTotal.value }}
        </div>

        <div>
            Week Total
            <mat-divider></mat-divider>
            {{ weeklyTotalsFormGroup.controls.weekTotal.value }}
        </div>
    </mat-card-content>
</mat-card>
