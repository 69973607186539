<app-dialog-header [title]="'Update Profile Image'"></app-dialog-header>

<div class="tw-flex tw-flex-row tw-items-center tw-justify-center tw-gap-5">
    @if (imageChangedEvent) {
        <image-cropper
            class="tw-w-fit tw-h-fit tw-items-center"
            [imageChangedEvent]="imageChangedEvent"
            [maintainAspectRatio]="true"
            [aspectRatio]="1 / 1"
            [cropperMinWidth]="300"
            [cropperMinHeight]="300"
            [cropperMaxWidth]="600"
            [cropperMaxHeight]="600"
            (imageCropped)="imageCropped($event)"
            (loadImageFailed)="loadImageFailed()"
            format="png"
        ></image-cropper>
    }

    <div class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <div
            class="tw-w-fit tw-h-fit tw-flex tw-items-center tw-justify-center tw-rounded-[50%]"
        >
            <img
                class="tw-w-[350px] tw-h-[350px] tw-flex tw-items-center tw-justify-center tw-rounded-[50%]"
                [src]="croppedImage ? croppedImage : currentProfileImage"
            />
        </div>
    </div>
</div>

<div class="tw-flex tw-justify-center tw-mt-3">
    <div>
        <button mat-raised-button (click)="fileInput.click()">
            <mat-icon class="select-file-icon">photo_camera</mat-icon>
            Choose Image
        </button>
        <input
            #fileInput
            type="file"
            (change)="fileChangeEvent($event)"
            class="tw-hidden"
            accept=".png, .jpeg, .jpg"
        />
    </div>
</div>

<app-dialog-footer
    (buttonClick)="saveImage()"
    (closeClick)="closeDialog()"
></app-dialog-footer>
