interface IPermissionJson {
    id: string;
    module: string;
    status: string;
    accessLevel: string;
}

interface IPermissionConstructor {
    id: string;
    module: string;
    status: string;
    accessLevel?: string;
}

export interface Permission extends IPermissionConstructor {
    id: string;
    module: string;
    status: string;
    accessLevel: string;
}

export class Permission {
    constructor(input?: IPermissionConstructor) {
        Object.assign(this, input);
    }

    static fromJson(json: IPermissionJson): Permission | null {
        if (json == null) {
            return null;
        }

        return new Permission({
            ...json,
        });
    }
}
