<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingLocations"
></mat-progress-bar>

<div
    class="tw-grid tw-grid-flow-row md:tw-grid-cols-10 tw-items-start tw-gap-4 tw-m-4"
>
    <mat-card
        class="tw-flex tw-flex-col tw-gap-2 tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-3 lg:tw-col-start-2 2xl:tw-col-start-2 tw-min-w-fit"
    >
        <mat-card-content class="tw-flex tw-flex-col tw-gap-2">
            <div class="tw-flex tw-flex-col tw-gap-4">
                <div [formGroup]="searchForm">
                    <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
                        <mat-label>Search Locations</mat-label>
                        <input matInput type="text" formControlName="search" />
                    </mat-form-field>
                </div>

                <div class="tw-flex tw-justify-between">
                    <mat-slide-toggle
                        class="tw-content-center"
                        (change)="toggleActiveInactive($event)"
                        [checked]="locationStatus"
                    >
                        <div *ngIf="locationStatus; else inactiveLocation">
                            Active
                        </div>
                        <ng-template #inactiveLocation>Inactive</ng-template>
                    </mat-slide-toggle>

                    <button
                        mat-raised-button
                        matTooltip="Add Location"
                        (click)="openAddDialog()"
                    >
                        + Location
                    </button>
                </div>

                <mat-selection-list
                    #locationSelectionList
                    hideSingleSelectionIndicator
                    class="scrollable"
                    multiple="false"
                >
                    @for (location of page.items; track location) {
                        <mat-list-option [value]="location">
                            {{ location.name }}, {{ location.state }}
                        </mat-list-option>
                        @if (!$last) {
                            <mat-divider></mat-divider>
                        }
                    }
                </mat-selection-list>
            </div>
        </mat-card-content>

        <mat-card-actions class="tw-flex tw-justify-end">
            <mat-paginator
                class="tw-rounded-b-lg tw-bg-transparent"
                [pageIndex]="pageIndex"
                [pageSize]="page.countRequested"
                [length]="page.totalCount"
                [hidePageSize]="true"
                (page)="changePage($event)"
            ></mat-paginator>
        </mat-card-actions>
    </mat-card>

    <div
        class="tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-5 tw-min-w-fit"
        *ngIf="optionSelected"
    >
        <app-edit-location
            (updateSuccess)="onUpdateSuccessLoad($event)"
            (loadingLocations)="locationsLoadingToggle($event)"
        ></app-edit-location>
    </div>
</div>
