import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { IUserContactInfoForm } from 'src/app/interfaces/user-contact-info-form';
import { IUserEmergencyContactForm } from 'src/app/interfaces/user-emergency-contact-form';
import { MatDividerModule } from '@angular/material/divider';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-personal-info',
    templateUrl: './personal-info.component.html',
    styleUrls: ['./personal-info.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatButtonModule,
        MatDividerModule,
        MatCardModule,
    ],
})
export class PersonalInfoComponent {
    @Input() userContactInfoForm: FormGroup<IUserContactInfoForm>;
    @Input() userEmergencyContactForm: FormGroup<IUserEmergencyContactForm>;

    @Output() saveContactInfoEvent = new EventEmitter();
    @Output() saveEmergencyContactEvent = new EventEmitter();

    constructor() {
        //TODO
    }

    userContactInfoSave(): void {
        this.saveContactInfoEvent.emit();
    }

    emergencyContactSaved(): void {
        this.saveEmergencyContactEvent.emit();
    }
}
