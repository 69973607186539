<form [formGroup]="locationForm">
    <mat-card>
        <mat-card-header
            class="tw-flex tw-justify-between tw-items-center tw-mb-2"
        >
            <mat-card-title>Edit Location</mat-card-title>
            <button mat-icon-button (click)="updateSuccess.emit(true)">
                <mat-icon class="tw-m-0 m3-error">close</mat-icon>
            </button>
        </mat-card-header>
        <mat-card-content class="tw-flex tw-flex-col tw-gap-2">
            <div
                class="tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-rounded-m3sm tw-p-2 tw-text-center"
                *ngIf="failed"
            >
                Failed to edit locations.
            </div>

            <mat-form-field appearance="fill">
                <mat-label>Office Name</mat-label>
                <input [readonly]="!location" matInput formControlName="name" />
                <mat-error
                    *ngIf="this.locationForm.controls.name.errors?.required"
                >
                    Office Name field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>City</mat-label>
                <input [readonly]="!location" matInput formControlName="city" />
                <mat-error
                    *ngIf="this.locationForm.controls.city.errors?.required"
                >
                    City field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>State</mat-label>
                <mat-select formControlName="state">
                    <mat-option
                        *ngFor="let state of states"
                        [value]="state.viewValue"
                    >
                        {{ state.viewValue }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="locationForm.controls.state.errors?.required">
                    State field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Zip Code</mat-label>
                <input
                    [readonly]="!location"
                    matInput
                    formControlName="zipCode"
                />
                <mat-error
                    *ngIf="this.locationForm.controls.zipCode.errors?.required"
                >
                    Zip Code field is required
                </mat-error>
            </mat-form-field>

            <mat-checkbox formControlName="status">Active</mat-checkbox>

            <div class="tw-flex tw-justify-between">
                <button
                    [disabled]="!location"
                    mat-raised-button
                    class="m3-error"
                    (click)="initializeForm()"
                >
                    Reset
                </button>

                <button mat-raised-button (click)="onFormSave()">
                    Save Changes
                </button>
            </div>
        </mat-card-content>
    </mat-card>
</form>
