<mat-progress-bar
    [appVisible]="loadingRoles"
    mode="indeterminate"
></mat-progress-bar>

<div
    class="tw-grid tw-grid-flow-row md:tw-grid-cols-10 tw-items-start tw-gap-4 tw-m-4"
>
    <mat-card
        class="tw-flex tw-flex-col tw-gap-2 tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-3 lg:tw-col-start-2 2xl:tw-col-start-2 tw-min-w-fit"
    >
        <mat-card-content class="tw-grid tw-grid-flow-row tw-gap-4">
            <div class="form-group">
                <mat-form-field class="tw-w-full" subscriptSizing="dynamic">
                    <input
                        matInput
                        type="text"
                        placeholder="Search Roles"
                        [formControl]="roleNameFormControl"
                    />
                </mat-form-field>
            </div>

            <div class="tw-flex tw-justify-between">
                <mat-slide-toggle
                    class="tw-content-center"
                    [checked]="isViewingActiveRoles"
                    (change)="toggleRolesList()"
                >
                    @if (isViewingActiveRoles) {
                        <ng-container>Active</ng-container>
                    } @else {
                        <ng-container>Inactive</ng-container>
                    }
                </mat-slide-toggle>

                <button
                    mat-raised-button
                    matTooltip="Add Role"
                    (click)="openDialog()"
                >
                    + Role
                </button>
            </div>

            <mat-selection-list
                hideSingleSelectionIndicator
                class="tw-max-h-[70vh] tw-overflow-y-auto"
                [multiple]="false"
                (selectionChange)="onSelectRole($event)"
            >
                @for (role of roles; track role) {
                    <mat-list-option
                        [value]="role"
                        [selected]="role.name === selectedRole?.name ?? null"
                    >
                        {{ role.name }}
                    </mat-list-option>
                    <mat-divider></mat-divider>
                }
            </mat-selection-list>
        </mat-card-content>
        <mat-card-actions class="tw-flex tw-justify-end">
            <mat-paginator
                class="tw-bg-transparent"
                [pageIndex]="pageIndex"
                [pageSize]="countSize"
                [length]="fullLength"
                [hidePageSize]="true"
                (page)="changePage($event)"
            ></mat-paginator>
        </mat-card-actions>
    </mat-card>

    @if (isRoleSelected) {
        <mat-card
            class="tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-5 tw-min-w-fit"
        >
            <mat-card-header
                class="tw-flex tw-justify-between tw-items-center tw-mb-2"
            >
                <mat-card-title>Edit Role</mat-card-title>
                <button mat-icon-button (click)="isRoleSelected = false">
                    <mat-icon class="tw-m-0 m3-error">close</mat-icon>
                </button>
            </mat-card-header>
            <mat-card-content>
                <table
                    mat-table
                    [dataSource]="selectedRolePermissions"
                    class="tw-bg-transparent tw-w-full"
                >
                    <ng-container matColumnDef="Module">
                        <th mat-header-cell *matHeaderCellDef>Module</th>
                        <td mat-cell *matCellDef="let permission">
                            {{ permission.module }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Full">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="tw-text-center"
                        >
                            Full Access
                        </th>

                        <td
                            mat-cell
                            *matCellDef="let permission"
                            class="tw-text-center tw-align-middle"
                        >
                            <mat-checkbox
                                [disabled]="!isViewingActiveRoles"
                                (change)="
                                    permission.accessLevel =
                                        permission.accessLevel === 'Admin'
                                            ? null
                                            : 'Admin'
                                "
                                [checked]="permission.accessLevel === 'Admin'"
                            ></mat-checkbox>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="Restricted">
                        <th
                            mat-header-cell
                            *matHeaderCellDef
                            class="tw-text-center"
                        >
                            Restricted Access
                        </th>

                        <td
                            mat-cell
                            *matCellDef="let permission"
                            class="tw-align-middle tw-text-center"
                        >
                            @if (
                                restrictedModules.includes(permission.module)
                            ) {
                                <mat-checkbox
                                    [disabled]="!isViewingActiveRoles"
                                    (change)="
                                        permission.accessLevel =
                                            permission.accessLevel ===
                                            'ReadOnly'
                                                ? null
                                                : 'ReadOnly'
                                    "
                                    [checked]="
                                        permission.accessLevel === 'ReadOnly'
                                    "
                                ></mat-checkbox>
                            }
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>

                <div class="tw-flex tw-justify-between">
                    @if (isViewingActiveRoles) {
                        <button
                            mat-raised-button
                            class="m3-error"
                            (click)="changeActiveStatus()"
                        >
                            Deactivate Role
                        </button>
                    } @else {
                        <button
                            mat-raised-button
                            [disabled]="!isRoleSelected"
                            (click)="changeActiveStatus()"
                        >
                            Activate Role
                        </button>
                    }

                    <button
                        mat-raised-button
                        (click)="selectUnselectAllPermissions()"
                    >
                        {{ !checkAll ? "Select All" : "Unselect All" }}
                    </button>

                    <button mat-raised-button (click)="savePermissionChanges()">
                        Save Changes
                    </button>
                </div>
            </mat-card-content>
        </mat-card>
    }
</div>
