<mat-card>
    <mat-card-header class="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <mat-card-title>Edit Client</mat-card-title>
        <button mat-icon-button (click)="clientChangedEvent.emit(false)">
            <mat-icon class="tw-m-0 m3-error">close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <form class="tw-grid tw-gap-2" [formGroup]="clientForm">
            <p class="warning" *ngIf="failed">Failed to edit clients.</p>

            <mat-form-field>
                <mat-label>Name</mat-label>
                <input [readonly]="!client" matInput formControlName="name" />
                <mat-error
                    *ngIf="this.clientForm.controls.name.errors?.required"
                >
                    Name is required
                </mat-error>
            </mat-form-field>

            <div class="tw-flex tw-flex-col tw-gap-2">
                <mat-checkbox
                    [disabled]="!client"
                    formControlName="signOffFlag"
                >
                    Client Sign Off
                </mat-checkbox>
                <mat-checkbox [disabled]="!client" formControlName="status">
                    Active
                </mat-checkbox>
                <mat-checkbox formControlName="nonBillable">
                    Non-Billable
                </mat-checkbox>
            </div>

            <div class="tw-flex tw-justify-between">
                <button
                    [disabled]="!client || loading"
                    mat-raised-button
                    (click)="openProject()"
                >
                    Open Projects
                </button>
                <button
                    [disabled]="!client || loading"
                    mat-raised-button
                    class="m3-error"
                    (click)="initializeForm(client)"
                >
                    Reset
                </button>
                <button
                    [disabled]="!client || loading"
                    mat-raised-button
                    (click)="onFormSave()"
                >
                    Save Changes
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
