<div
    class="tw-absolute tw-h-screen tw-w-screen tw-z-[-1] tw-bg-center tw-bg-no-repeat tw-bg-cover"
    style="
        background-image: url(https://psportalqa.blob.core.windows.net/logo-container/LoginBackground.png);
    "
></div>

<div>
    <div class="tw-mt-24">
        <img
            bind-src="
                'https://psportalqa.blob.core.windows.net/logo-container/PSS-Logo-Dark-Mode.png'
            "
            class="tw-block tw-w-1/4 tw-mx-auto tw-mb-auto tw-p-2"
        />
    </div>

    <div
        class="tw-w-full tw-h-[35%] tw-flex tw-justify-center tw-items-center tw-mt-auto"
    >
        <form
            [formGroup]="loginForm"
            class="tw-w-1/4 tw-flex tw-flex-col tw-justify-center tw-items-center"
            (ngSubmit)="onLoginClick()"
        >
            <p class="tw-text-red-400" *ngIf="enteredIncorrectly">
                Incorrect Username and Password
            </p>

            <mat-form-field class="tw-w-full m3-tertiary" appearance="fill">
                <mat-label>Email</mat-label>
                <input name="email" matInput formControlName="email" />
                <mat-error *ngIf="this.loginForm.get('email').invalid">
                    {{ getErrorMessage(this.loginForm.get("email")) }}
                </mat-error>
            </mat-form-field>

            <mat-form-field class="tw-w-full m3-tertiary" appearance="fill">
                <mat-label>Password</mat-label>
                <input
                    name="password"
                    matInput
                    [type]="showPassword ? 'text' : 'password'"
                    formControlName="password"
                />
                <mat-icon matSuffix (click)="showPassword = !showPassword">
                    {{ showPassword ? "visibility_off" : "visibility" }}
                </mat-icon>
                <mat-error *ngIf="this.loginForm.get('password').invalid">
                    You must enter a value
                </mat-error>
            </mat-form-field>

            <ng-container *ngIf="!loadingSpinnerActive">
                <div class="tw-grid tw-gap-2 tw-grid-flow-row">
                    <button mat-raised-button type="submit">Login</button>

                    <button
                        mat-raised-button
                        class="m3-error"
                        routerLink="/forgot-password"
                        routerLinkActive="active"
                    >
                        Forgot Password
                    </button>
                </div>
            </ng-container>

            <ng-container *ngIf="loadingSpinnerActive">
                <mat-spinner></mat-spinner>
            </ng-container>
        </form>
    </div>
</div>
