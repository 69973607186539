import { Component, OnInit, ViewChild } from '@angular/core';
import { saveAs } from 'file-saver';
import { Page } from 'src/app/models/page.model';
import {
    addWeeks,
    endOfISOWeek,
    getISOWeek,
    getISOWeekYear,
    isSameDay,
    startOfISOWeek,
    sub,
} from 'date-fns';
import {
    FormControl,
    FormGroup,
    ReactiveFormsModule,
    FormsModule,
} from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { ITimesheetDateFormControls } from 'src/app/interfaces/timesheets';
import { User } from 'src/app/models/User';
import { AdminTimesheet } from 'src/app/models/admin-timesheet.model';
import { UserService } from 'src/app/services/user.service';
import { TimesheetService } from 'src/app/services/timesheet-service.service';
import { AdminTimesheetService } from 'src/app/services/admin-timesheet.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { Setting } from 'src/app/models/setting.model';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { FileDialogComponent } from '../file-dialog/file-dialog.component';
import { ReportsService } from 'src/app/services/reports.service';
import { EmailMultiButtonDialogComponent } from '../email-multi-button-dialog/email-multi-button-dialog.component';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
    MatDatepickerInputEvent,
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import {
    animate,
    state,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TimesheetItemComponent } from '../timesheet-item/timesheet-item.component';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { TimesheetDate } from 'src/app/models/timesheet-date-info.model';
import { ViewTimesheetComponent } from './view-timesheet/view-timesheet.component';

@Component({
    selector: 'app-admin-timesheet',
    templateUrl: './admin-timesheet.html',
    styleUrls: ['./admin-timesheet.scss'],
    standalone: true,
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition(
                'expanded <=> collapsed',
                animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'),
            ),
        ]),
    ],
    imports: [
        NgIf,
        MatProgressBarModule,
        MatButtonModule,
        MatTooltipModule,
        MatIconModule,
        MatFormFieldModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatCheckboxModule,
        FormsModule,
        MatInputModule,
        MatTableModule,
        MatExpansionModule,
        CommonModule,
        MatDividerModule,
        VisibleDirective,
        MatSort,
        MatSortModule,
        MatCardModule,
        ViewTimesheetComponent,
    ],
})
export class AdminTimesheetsComponent implements OnInit {
    settings: Setting[] = [];
    debounceTime = 300;
    reports: AdminTimesheet;
    defaultUsers: User[];
    loadingUsers = false;
    queryParams = new Map<string, string | boolean>();
    users: User[];
    is1099Only: boolean = false;
    isUnsubmittedOnly: boolean = false;
    isSelectAll: boolean = false;
    lastSelectedDate: TimesheetDate;

    page = new Page<AdminTimesheet>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });
    filteredPage = new Page<AdminTimesheet>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    dataSource: MatTableDataSource<AdminTimesheet>;
    @ViewChild(MatSort) sort: MatSort;

    defaultEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: '',
        body: '',
    };

    firstNoticeEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: '',
        body: '',
    };

    finalNoticeEmail: {
        subject: string | null;
        body: string | null;
    } = {
        subject: '',
        body: '',
    };

    dateFormGroup = new FormGroup<ITimesheetDateFormControls>({
        beginningOfWeek: new FormControl<Date>(startOfISOWeek(new Date())),
        endOfWeek: new FormControl<Date>(endOfISOWeek(new Date())),
        selectedWeek: new FormControl<number>(null),
        selectedYear: new FormControl<number>(null),
    });

    searchForm = new FormGroup({
        searchBar: new FormControl<string | null>(null),
    });

    displayedColumns = ['selected', 'name', 'billingHours', 'expand'];
    expandedTimesheet: null;

    constructor(
        private adminTimesheetService: AdminTimesheetService,
        private userService: UserService,
        private reportService: ReportsService,
        private timesheetService: TimesheetService,
        private dialog: MatDialog,
        private snackBar: MatSnackBar,
    ) {
        const currentDate = new Date();
        let dateOfConsideration = currentDate;
        if (
            isSameDay(currentDate, startOfISOWeek(currentDate)) &&
            currentDate.getHours() < 12
        ) {
            dateOfConsideration = sub(currentDate, { days: 1 });
        }
        this.lastSelectedDate = new TimesheetDate({
            beginningOfWeek: startOfISOWeek(dateOfConsideration),
            endOfWeek: endOfISOWeek(dateOfConsideration),
            selectedWeek: getISOWeek(dateOfConsideration),
            selectedYear: getISOWeekYear(dateOfConsideration),
        });
    }

    ngOnInit(): void {
        this.updateDate();

        this.dateFormGroup.controls.beginningOfWeek.valueChanges
            .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
            .subscribe(() => this.updateDate());

        this.searchForm
            .get('searchBar')
            .valueChanges.pipe(
                debounceTime(this.debounceTime),
                distinctUntilChanged(),
            )
            .subscribe((value) => this.filterName(value));
    }

    private setWeekAndYear() {
        this.dateFormGroup.controls.selectedYear.setValue(
            getISOWeekYear(this.dateFormGroup.controls.beginningOfWeek.value),
        );
        this.dateFormGroup.controls.selectedWeek.setValue(
            getISOWeek(this.dateFormGroup.controls.beginningOfWeek.value),
        );
    }

    updateDate(): void {
        this.setWeekAndYear();
        this.refresh();
    }

    onClickNextWeek(): void {
        this.dateFormGroup.controls.endOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.endOfWeek.value, 1),
        );
        this.dateFormGroup.controls.beginningOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.beginningOfWeek.value, 1),
        );
    }

    onClickPreviousWeek(): void {
        this.dateFormGroup.controls.endOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.endOfWeek.value, -1),
        );
        this.dateFormGroup.controls.beginningOfWeek.setValue(
            addWeeks(this.dateFormGroup.controls.beginningOfWeek.value, -1),
        );
    }

    onStartDateChange(event: MatDatepickerInputEvent<Date>): void {
        const selectedDate = event.value;
        this.dateFormGroup.controls.beginningOfWeek.setValue(
            startOfISOWeek(selectedDate),
        );
        this.dateFormGroup.controls.endOfWeek.setValue(
            endOfISOWeek(selectedDate),
        );
    }

    onEndDateChange(event: MatDatepickerInputEvent<Date>): void {
        const selectedDate = event.value;
        this.dateFormGroup.controls.beginningOfWeek.setValue(
            startOfISOWeek(selectedDate),
        );
        this.dateFormGroup.controls.endOfWeek.setValue(
            endOfISOWeek(selectedDate),
        );
    }

    setQueryParams(): void {
        this.queryParams.set(
            'week',
            getISOWeek(
                this.dateFormGroup.controls.beginningOfWeek.value,
            ).toString(),
        );
        this.queryParams.set(
            'year',
            this.dateFormGroup.controls.selectedYear.value.toString(),
        );
    }

    getReports(): void {
        this.loadingUsers = true;
        this.setQueryParams();

        this.adminTimesheetService
            .get(['admin'], this.queryParams)
            .subscribe((response: Page<AdminTimesheet>) => {
                this.page = { ...response };

                const queryParams = new Map<string, string | number>();
                queryParams.set('count', -1);
                queryParams.set('status', ACTIVE);

                this.userService
                    .get([], queryParams)
                    .subscribe((response: Page<User>) => {
                        this.users = response.items;

                        for (const user of this.users) {
                            this.page.items.forEach((userItem) => {
                                if (userItem.userProfileId == user.id) {
                                    const queryParams: Map<string, number> =
                                        new Map([
                                            [
                                                'week',
                                                getISOWeek(
                                                    this.dateFormGroup.controls
                                                        .beginningOfWeek.value,
                                                ),
                                            ],
                                            [
                                                'year',
                                                this.dateFormGroup.controls
                                                    .selectedYear.value,
                                            ],
                                        ]);

                                    this.timesheetService
                                        .getTimesheets(
                                            ['user', user.userLoginId],
                                            queryParams,
                                        )
                                        .subscribe((response) => {
                                            userItem.timesheetGroup = response;
                                        });
                                }
                            });
                        }

                        this.defaultUsers = [...this.users].filter(
                            (user) =>
                                !this.page.items.some(
                                    (userReport) =>
                                        user.id == userReport.userProfileId,
                                ),
                        );

                        for (const user of this.defaultUsers) {
                            const newAdminTimesheet: AdminTimesheet = {
                                userProfileId: user.id,
                                name: user.firstName + ' ' + user.lastName,
                                billingHours: 0,
                                ptoHours: null,
                                week: getISOWeek(
                                    this.dateFormGroup.controls.beginningOfWeek
                                        .value,
                                ),
                                year: this.dateFormGroup.controls.selectedYear
                                    .value,
                                isSelected: false,
                                is1099: user.is1099,
                                submitted: false,
                                timesheetGroup: null,
                            };

                            this.page.items.push(newAdminTimesheet);
                        }

                        this.page.items.sort((a, b) =>
                            a.name.localeCompare(b.name),
                        );

                        this.filteredPage = { ...this.page };
                        this.dataSource = new MatTableDataSource(
                            this.filteredPage.items,
                        );
                        this.loadingUsers = false;
                        this.dataSource.sort = this.sort;
                    });
            });
    }

    unlockReports() {
        for (const report of this.filteredPage.items) {
            if (report.isSelected) {
                this.toggleLockedStatus(report, false);
            }
        }
    }

    lockAllReports() {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: 'Are you sure you want to lock timesheets?',
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                for (const report of this.page.items) {
                    this.toggleLockedStatus(report, true);
                }
            }
        });
    }

    protected toggleLockedStatus(
        adminItem: AdminTimesheet,
        locked: boolean,
    ): void {
        const queryParams: Map<string, unknown> = new Map<string, unknown>([
            ['week', adminItem.week],
            ['year', adminItem.year],
            ['isLocked', locked],
        ]);

        this.timesheetService
            .put(
                ['LockUnlockTimesheet', adminItem.userProfileId],
                {},
                queryParams,
            )
            .subscribe(() => {
                this.refresh();
            });
    }

    refresh(): void {
        this.searchForm.reset();
        this.isSelectAll = false;
        this.isUnsubmittedOnly = false;
        this.is1099Only = false;
        this.getReports();
    }

    filterName(strSearch: string) {
        if (strSearch != null && strSearch != '') {
            if (this.is1099Only) {
                this.filteredPage.items = this.filteredPage.items.filter(
                    (filteredItem) =>
                        filteredItem.is1099 == true &&
                        filteredItem.name
                            .toLowerCase()
                            .includes(strSearch.toLowerCase()),
                );
            }
            if (this.isUnsubmittedOnly) {
                this.filteredPage.items = this.filteredPage.items.filter(
                    (filteredItem) =>
                        filteredItem.submitted == false &&
                        filteredItem.name
                            .toLowerCase()
                            .includes(strSearch.toLowerCase()),
                );
            }
            if (!this.is1099Only && !this.isUnsubmittedOnly) {
                this.filteredPage.items = [...this.page.items].filter(
                    (report) => {
                        return report.name
                            .toLowerCase()
                            .includes(strSearch.toLowerCase());
                    },
                );
            }
        } else {
            this.filteredPage.items = [...this.page.items];

            if (this.isSelectAll) {
                for (const visibleItem of this.filteredPage.items) {
                    visibleItem.isSelected = true;
                }
            }

            if (this.is1099Only) {
                this.filteredPage.items = this.filteredPage.items.filter(
                    (filteredItem) => filteredItem.is1099 == true,
                );
            }

            if (this.isUnsubmittedOnly) {
                this.filteredPage.items = this.filteredPage.items.filter(
                    (filteredItem) => filteredItem.submitted == false,
                );
            }
        }
        this.dataSource = new MatTableDataSource(this.filteredPage.items);
        this.dataSource.sort = this.sort;
    }

    onSelectChange(): void {
        this.filterName(this.searchForm.get('searchBar').value);

        for (const item of this.filteredPage.items) {
            if (
                (this.is1099Only && !item.is1099) ||
                (this.isUnsubmittedOnly && item.submitted)
            ) {
                this.filteredPage.items = this.filteredPage.items.filter(
                    (filteredItem) => filteredItem !== item,
                );
            }
        }
        this.dataSource = new MatTableDataSource(this.filteredPage.items);
        this.dataSource.sort = this.sort;
    }

    selectAll(): void {
        for (const visibleItem of this.filteredPage.items) {
            visibleItem.isSelected = this.isSelectAll;
        }
    }

    openEmailDialog(): void {
        const unsubmittedUsers = this.page.items;
        this.dialog.open(EmailMultiButtonDialogComponent, {
            width: '30%',
            height: '70%',
            data: { unsubmittedUsers: unsubmittedUsers, users: this.users },
        });
    }

    showNotification(message: string): void {
        const config = new MatSnackBarConfig();

        config.duration = 5000;
        config.verticalPosition = 'top';

        this.snackBar.open(message, 'Close', config);
    }

    exportReport(): void {
        const dialog = this.dialog.open(FileDialogComponent);

        dialog.afterClosed().subscribe((result: boolean) => {
            if (result != undefined) {
                this.loadingUsers = true;

                if (result) {
                    this.queryParams.set('type', 'pdf');

                    this.reportService
                        .exportReport(['payroll', 'export'], this.queryParams)
                        .subscribe((buffer: Blob) => {
                            const data: Blob = new Blob([buffer], {
                                type: 'text/pdf',
                            });

                            saveAs(data, 'admin-report.pdf');
                            this.loadingUsers = false;
                        });
                } else {
                    this.queryParams.set('type', 'csv');

                    this.reportService
                        .exportReport(['payroll', 'export'], this.queryParams)
                        .subscribe((buffer: Blob) => {
                            const data: Blob = new Blob([buffer], {
                                type: 'text/csv',
                            });

                            saveAs(data, 'admin-report.csv');
                            this.loadingUsers = false;
                        });
                }
            }
        });
    }
}
