import { Component, Inject, OnInit } from '@angular/core';
import {
    FormControl,
    FormGroup,
    Validators,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    MatDialogRef,
    MAT_DIALOG_DATA,
    MatDialogContent,
    MatDialog,
} from '@angular/material/dialog';
import { TaskService } from 'src/app/services/task-service.service';
import { Task } from '../../models/task.model';
import { billType } from 'src/app/enums/billType.enum';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { DialogFooterComponent } from '../dialog-footer/dialog-footer.component';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgIf, NgFor, KeyValuePipe } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogHeaderComponent } from '../dialog-header/dialog-header.component';
import { ApproversService } from 'src/app/services/approvers.service';
import { User } from 'src/app/models/User';
import { COMMA, ENTER, P } from '@angular/cdk/keycodes';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { EditApproverDialogComponent } from '../edit-approver-dialog/edit-approver-dialog.component';

interface IUpsertTaskDialogData {
    title: string;
    task?: Task;
    projectId: string;
    projectName: string;
    clientName: string;
    billType: string;
    approverRequired: boolean;
}

interface RequestBody {
    userIds: string[];
    taskId: string;
}

interface ApproverResponse {
    success: boolean;
    taskApprovers: User[];
}

@Component({
    selector: 'app-upsert-task',
    templateUrl: './upsert-task.component.html',
    styleUrls: ['./upsert-task.component.scss'],
    standalone: true,
    imports: [
        DialogHeaderComponent,
        MatDialogContent,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        NgIf,
        MatCheckboxModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MatButtonModule,
        DialogFooterComponent,
        KeyValuePipe,
        MatChipsModule,
        MatAutocompleteModule,
        MatInputModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
    ],
})
export class UpsertTaskComponent implements OnInit {
    saving = false;

    billType = billType;

    uneditable = true;

    taskForm: FormGroup;

    approvers: User[];

    taskApprovers: User[] = [];

    approversEdited: boolean = false;

    filteredApprovers: User[] = [];

    separatorKeysCodes: number[] = [ENTER, COMMA];

    queryParams: Map<string, unknown> = new Map<string, unknown>([
        ['status', ACTIVE],
        ['count', -1],
        ['offset', 0],
        ['approver', 'true'],
    ]);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: IUpsertTaskDialogData,
        private dialogRef: MatDialogRef<UpsertTaskComponent>,
        private taskService: TaskService,
        private approversService: ApproversService,
        private dialog: MatDialog,
    ) {}

    ngOnInit(): void {
        this.initializeForm();

        this.taskForm
            .get('approverRequired')
            .valueChanges.subscribe((value: boolean) => {
                this.approverRequiredChanged(value);
            });
    }

    initializeForm(): void {
        if (!this.data.task) {
            this.taskForm = new FormGroup({
                id: new FormControl(null),
                name: new FormControl(null, [
                    Validators.required.bind(this),
                    Validators.maxLength(100),
                ]),
                projectId: new FormControl(this.data.projectId),
                clientName: new FormControl(this.data.clientName),
                projectName: new FormControl(this.data.projectName),
                billType: new FormControl('Billable'),
                approverRequired: new FormControl(false),
            });
        } else {
            let checked;
            this.data.task.status.toLowerCase() === ACTIVE.toLowerCase()
                ? (checked = true)
                : (checked = false);
            this.taskForm = new FormGroup({
                id: new FormControl(this.data.task?.id),
                name: new FormControl(this.data.task?.name, [
                    Validators.required.bind(this),
                    Validators.maxLength(100),
                ]),
                projectId: new FormControl(this.data.projectId),
                clientName: new FormControl(this.data.clientName),
                projectName: new FormControl(this.data.projectName),
                billType: new FormControl(this.data.billType),
                status: new FormControl(checked),
                approverRequired: new FormControl(
                    this.data.task.approverRequired,
                ),
            });
        }
    }

    approverRequiredChanged(required: boolean) {
        if (required) {
            this.openApproverDialog();
        }
    }

    openApproverDialog(): void {
        const dialogData = {
            title: 'Add/Edit Approvers',
        };

        if (this.data.task && this.data.task.id) {
            dialogData['taskId'] = this.data.task.id;
        }

        if (this.taskApprovers) {
            dialogData['taskApprovers'] = [...this.taskApprovers];
        }

        const dialogRef = this.dialog.open(EditApproverDialogComponent, {
            data: dialogData,
            minWidth: '100vh',
        });

        dialogRef.afterClosed().subscribe((dialogResult: ApproverResponse) => {
            if (dialogResult) {
                this.taskApprovers = dialogResult.taskApprovers;
                this.approversEdited = true;
            }

            if (this.taskApprovers.length <= 0 && this.approversEdited) {
                this.taskForm.controls.approverRequired.setValue(false);
            }
        });
    }

    getApproverIds(): string[] {
        const taskApproverIds: string[] = [];
        for (const approver of this.taskApprovers) {
            taskApproverIds.push(approver.userLoginId);
        }
        return taskApproverIds;
    }

    onSaveClick(): void {
        if (this.taskForm.valid) {
            this.saving = true;
            const task = new Task(this.taskForm.value);
            if (this.data.task?.id) {
                if (this.taskForm.controls.status.value === true) {
                    task.status = ACTIVE.toUpperCase();
                } else {
                    task.status = INACTIVE.toUpperCase();
                }

                this.taskService.put([task.id], task).subscribe(() => {
                    this.dialogRef.close(true);
                });

                this.updateApprovers(task.id);
            } else {
                task.status = ACTIVE.toUpperCase();
                this.taskService.post([], task).subscribe((response: any) => {
                    this.updateApprovers(response.id);
                });
            }
        }
    }

    updateApprovers(id: string) {
        if (this.approversEdited) {
            let requestBody: RequestBody;

            if (this.taskForm.controls.approverRequired.value) {
                requestBody = {
                    userIds: this.getApproverIds(),
                    taskId: id,
                };
            } else {
                requestBody = {
                    userIds: [],
                    taskId: id,
                };
            }

            this.approversService.post([], requestBody).subscribe();
        }

        this.dialogRef.close(true);
    }

    closeDialog(): void {
        this.dialogRef.close();
    }
}
