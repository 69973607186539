import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Client } from 'src/app/models/client.model';
import { Page } from 'src/app/models/page.model';
import { WorkOrder } from 'src/app/models/work-order.model';
import { PageEvent, MatPaginatorModule } from '@angular/material/paginator';
import { ClientService } from 'src/app/services/client.service';
import { Router } from '@angular/router';
import { WorkOrderService } from 'src/app/services/work-order.service';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf, NgFor, SlicePipe, DatePipe } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatCardModule } from '@angular/material/card';
import { P } from '@angular/cdk/keycodes';
@Component({
    selector: 'app-work-orders',
    templateUrl: './work-orders.component.html',
    styleUrls: ['./work-orders.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatProgressBarModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatTableModule,
        MatIconModule,
        MatPaginatorModule,
        SlicePipe,
        DatePipe,
        VisibleDirective,
        MatSort,
        MatSortModule,
        MatCardModule,
    ],
})
export class WorkOrdersComponent implements OnInit {
    filterForm = new FormGroup({
        name: new FormControl<string>(null),
        clientId: new FormControl<string>(null),
    });

    showOptions = false;
    loadingReport = false;
    enableToggle = false;
    showFilterMenu: boolean = false;
    pageIndex: number = 0;
    clients: Client[];
    queryParams = new Map();
    activeWorkOrders: WorkOrder[];
    sortAscending: string = null;
    sortProperty: string = null;
    clientsWithOrders: Client[];
    filteredClients: Client[];
    isSortInitialized: boolean = false;

    displayedColumns = ['name', 'clientName', 'createDate', 'open', 'new'];

    page = new Page<WorkOrder>({
        countRequested: 10,
        offsetRequested: 0,
        items: [],
        totalCount: 0,
    });

    dataSource: MatTableDataSource<WorkOrder>;
    @ViewChild(MatSort) sort: MatSort;

    constructor(
        private clientService: ClientService,
        private router: Router,
        private workOrderService: WorkOrderService,
    ) {}

    ngOnInit(): void {
        this.filterForm.controls.name.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterChange();
        this.getWorkOrders();
        this.getClients();
    }

    onSortChange(): void {
        if (this.sort.active) {
            this.queryParams.set('sortProperty', this.sort.active);
            this.queryParams.set(
                'isSortAscending',
                this.sort.direction === 'asc',
            );
        } else {
            this.queryParams.delete('sortProperty');
            this.queryParams.delete('isSortAscending');
        }

        this.getWorkOrders();
    }

    getWorkOrders(): void {
        this.loadingReport = true;

        this.workOrderService
            .get([], this.queryParams)
            .subscribe((response: Page<WorkOrder>) => {
                this.page = response;
                this.dataSource = new MatTableDataSource(this.page.items);
                this.page.items.map((item) => {
                    const name = this.getClientName(item.clientId);
                    item['clientName'] = name;
                });
                this.dataSource.sort = this.sort;
                if (!this.isSortInitialized && this.sort) {
                    this.sort.sortChange.subscribe(() => this.onSortChange());
                    this.isSortInitialized = true; // Mark as initialized
                }
                this.loadingReport = false;
            });

        this.workOrderService.get([]).subscribe((response: Page<WorkOrder>) => {
            this.activeWorkOrders = response.items.filter(
                (item) => item.active === true,
            );
            this.activeWorkOrders.sort((a, b) => {
                return a.name.localeCompare(b.name);
            });
        });
    }

    filterChange(): void {
        this.queryParams.set('count', 10);

        if (
            this.filterForm.controls.clientId.value &&
            this.filterForm.controls.clientId.value !== ''
        ) {
            this.queryParams.set(
                'clientId',
                this.filterForm.controls.clientId.value,
            );
            this.pageIndex = 0;
        } else {
            this.queryParams.delete('clientId');
        }

        if (
            this.filterForm.controls.name.value &&
            this.filterForm.controls.name.value !== ''
        ) {
            this.queryParams.set('name', this.filterForm.controls.name.value);
            this.pageIndex = 0;
        } else {
            this.queryParams.delete('name');
        }

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        this.getWorkOrders();
    }

    changePageIndex(index: number) {
        this.pageIndex = index;
        this.filterChange();
    }

    showFilters(): void {
        this.showFilterMenu = !this.showFilterMenu;
        this.clearFilters();
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    clearFilters(): void {
        this.queryParams.delete('name');
        this.queryParams.delete('clientId');
        this.queryParams.delete('sortProperty');
        this.queryParams.delete('isSortAscending');

        this.filterForm = new FormGroup({
            name: new FormControl(null),
            clientId: new FormControl(null),
        });

        this.filterForm.controls.name.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.filterForm.controls.clientId.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe(() => this.filterChange());

        this.getWorkOrders();
        this.changePageIndex(0);
    }

    openWorkOrder(creating: boolean, workOrder?: WorkOrder) {
        if (workOrder) {
            this.router.navigate(['openworkorder'], {
                queryParams: { workOrderId: workOrder.id, creating: creating },
            });
        } else {
            this.router.navigate(['openworkorder'], {
                queryParams: { creating: creating },
            });
        }
    }

    getClientName(clientId: string): string {
        if (this.clients && this.clients.length > 0) {
            const client = this.clients.find(
                (client) => client.id === clientId,
            );
            return client ? client.name : 'Unknown Client';
        } else {
            return 'Unknown Client';
        }
    }

    filterClients(name: string) {
        this.filteredClients = this.clientsWithOrders.filter((client) =>
            client.name.toLowerCase().includes(name.toLowerCase()),
        );
    }

    private getClients(): void {
        var queryParams = new Map<string, any>();
        queryParams.set('count', -1);

        this.clientService
            .get([], queryParams)
            .subscribe((response: Page<Client>) => {
                this.clients = response.items;

                this.workOrderService
                    .get([], queryParams)
                    .subscribe((response: Page<WorkOrder>) => {
                        this.clientsWithOrders = this.clients.filter(
                            (client) => {
                                return response.items.some(
                                    (workOrder) =>
                                        workOrder.clientId === client.id,
                                );
                            },
                        );
                        this.filterClients('');
                    });
            });
    }
}
