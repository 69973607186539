<div>
    <h2 class="tw-m-4">Select your location:</h2>
    <div class="tw-flex tw-flex-row tw-m-4">
        <div *ngFor="let location of page.items">
            <button
                mat-raised-button
                class="tw-mr-4"
                (click)="displayWOL(location)"
            >
                {{ location.city }}
                <mat-icon>{{ getCityIcon(location.city) }}</mat-icon>
            </button>
        </div>
    </div>
    <ng-container *ngIf="url !== null">
        <iframe
            [src]="url | safe"
            width="1600"
            height="800"
            class="tw-m-auto"
        ></iframe>
    </ng-container>
</div>
