<app-dialog-header [title]="data.title"></app-dialog-header>
<div mat-dialog-content class="tw-max-h-max tw-w-full">
    <form [formGroup]="taskForm" class="tw-flex tw-flex-col tw-mb-2 tw-w-full">
        <mat-form-field appearance="fill">
            <mat-label>Task Name</mat-label>
            <input matInput formControlName="name" />
        </mat-form-field>

        <ng-container *ngIf="data.task">
            <mat-checkbox formControlName="status">Status</mat-checkbox>
        </ng-container>

        <div class="flex-row">
            <mat-form-field class="pb-1 pt-1 tw-w-full" appearance="fill">
                <mat-label>Select your Bill Type</mat-label>
                <mat-select formControlName="billType" value="Billable">
                    <mat-option
                        *ngFor="let type of billType | keyvalue"
                        [value]="type.key"
                    >
                        {{ type.value }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="tw-flex tw-flex-row tw-justify-end">
            <mat-checkbox
                class="mb-1"
                formControlName="approverRequired"
                class="tw-mr-auto"
            >
                Approver Required
            </mat-checkbox>

            <button
                mat-raised-button
                *ngIf="taskForm.get('approverRequired').value"
                (click)="openApproverDialog()"
            >
                Edit Approvers
            </button>
        </div>
    </form>
</div>

<app-dialog-footer
    [disabled]="saving"
    (buttonClick)="onSaveClick()"
    (closeClick)="closeDialog()"
></app-dialog-footer>
