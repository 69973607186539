<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loadingDocuments"
></mat-progress-bar>

<div class="tw-grid tw-grid-cols-8 tw-gap-4 tw-items-start tw-my-4">
    <mat-card class="tw-gap-2 tw-col-span-2 tw-col-start-2">
        <mat-card-content>
            <div class="tw-flex tw-justify-between tw-items-center tw-mb-2">
                <mat-slide-toggle
                    [checked]="isTreeCollapsed"
                    (change)="onTreeCollapseChange()"
                >
                    <span class="tw-text-xs">
                        {{ treeCollapseToggle }}
                    </span>
                </mat-slide-toggle>

                <button
                    [disabled]="restricted"
                    mat-raised-button
                    [matTooltip]="
                        restricted
                            ? 'You do not have permission to create categories.'
                            : ''
                    "
                    matTooltip="Add Category"
                    (click)="createContainer()"
                >
                    + Category
                </button>
            </div>

            <div class="tw-overflow-auto tw-max-h-[65vh]">
                <mat-tree
                    [dataSource]="dataSource"
                    [treeControl]="treeControl"
                    class="tw-bg-transparent"
                >
                    <mat-tree-node
                        class="tw-cursor-pointer tw-gap-2 hover:tw-bg-m3SurfaceContainer-Highest"
                        *matTreeNodeDef="let node"
                        matTreeNodePadding
                        (click)="viewFile(node)"
                        (contextmenu)="onRightClick($event, node)"
                    >
                        <button mat-icon-button disabled></button>
                        <mat-icon>document_scanner</mat-icon>
                        <div>
                            {{ node.name }}
                        </div>
                    </mat-tree-node>

                    <mat-tree-node
                        *matTreeNodeDef="let node; when: hasChild"
                        matTreeNodePadding
                    >
                        <button
                            mat-icon-button
                            matTreeNodeToggle
                            [attr.aria-label]="'Toggle ' + node.name"
                        >
                            <mat-icon>
                                {{
                                    treeControl.isExpanded(node)
                                        ? "expand_more"
                                        : "chevron_right"
                                }}
                            </mat-icon>
                        </button>

                        <div
                            class="tw-cursor-pointer tw-flex tw-items-center tw-gap-2"
                            (contextmenu)="onRightClick($event, node)"
                        >
                            <mat-icon>folder</mat-icon>
                            <div>
                                {{ node.name }}
                            </div>
                        </div>
                        <div
                            [matTooltip]="
                                restricted
                                    ? 'You do not have permission to create documents.'
                                    : ''
                            "
                        >
                            <button
                                class="tw-cursor-pointer"
                                mat-icon-button
                                [disabled]="restricted"
                                (click)="openAddDialog(node)"
                            >
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </mat-tree-node>
                </mat-tree>
            </div>

            <div
                class="tw-invisible tw-fixed"
                [style.left]="menuTopLeftPosition.x"
                [style.top]="menuTopLeftPosition.y"
                [matMenuTriggerFor]="rightMenu"
            ></div>

            <mat-menu #rightMenu="matMenu">
                <ng-template matMenuContent let-item="item">
                    <button
                        mat-menu-item
                        [disabled]="restricted"
                        [matTooltip]="
                            restricted
                                ? 'You do not have permission to delete documents.'
                                : ''
                        "
                        (click)="removeDocument(item.document)"
                    >
                        Delete
                    </button>
                </ng-template>
            </mat-menu>
        </mat-card-content>
    </mat-card>

    <mat-card class="tw-self-start tw-col-span-4" *ngIf="showDocument">
        <mat-card-content class="tw-flex tw-flex-col tw-gap-4">
            <div class="tw-flex tw-gap-4 tw-self-end">
                <button mat-raised-button (click)="downloadFile()">
                    <mat-icon>download</mat-icon>
                    Download
                </button>

                <button
                    mat-raised-button
                    class="m3-error"
                    (click)="showDocument = !showDocument"
                >
                    <mat-icon>close</mat-icon>
                    Close
                </button>
            </div>

            <pdf-viewer
                [src]="docInt8Array"
                [rotation]="0"
                [original-size]="false"
                [show-all]="true"
                [fit-to-page]="false"
                [zoom]="1"
                [zoom-scale]="'page-fit'"
                [stick-to-page]="false"
                [render-text]="true"
                [external-link-target]="'blank'"
                [autoresize]="true"
                [show-borders]="false"
                class="tw-w-full tw-h-[75vh]"
            ></pdf-viewer>
        </mat-card-content>
    </mat-card>
</div>
