<mat-progress-bar
    mode="indeterminate"
    *ngIf="loadingUserFeedback"
></mat-progress-bar>
<form [formGroup]="searchForm">
    <div
        class="tw-grid tw-grid-flow-row md:tw-grid-cols-10 tw-items-start tw-gap-4 tw-m-4"
    >
        <mat-card
            class="tw-flex tw-flex-col tw-gap-2 tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-3 lg:tw-col-start-2 2xl:tw-col-start-2 tw-min-w-fit"
        >
            <mat-card-content class="tw-flex tw-flex-col">
                <div
                    [formGroup]="searchForm"
                    class="tw-flex tw-flex-col tw-w-full"
                >
                    @if (!userView) {
                        <mat-form-field appearance="fill">
                            <mat-label>User</mat-label>
                            <mat-select
                                formControlName="userSearch"
                                (selectionChange)="setQueryParams()"
                            >
                                <mat-option value="">View All</mat-option>
                                @for (user of users; track user) {
                                    <mat-option [value]="user.id">
                                        {{
                                            user.firstName + " " + user.lastName
                                        }}
                                    </mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    }
                    <mat-form-field appearance="fill">
                        <mat-label>Status</mat-label>
                        <mat-select
                            formControlName="statusSearch"
                            (selectionChange)="setQueryParams()"
                        >
                            <mat-option value="">View All</mat-option>
                            <mat-option
                                *ngFor="let status of feedbackStatuses"
                                [value]="status"
                            >
                                {{ status }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <mat-selection-list
                    #FeedbackSelectionList
                    hideSingleSelectionIndicator
                    multiple="false"
                    (selectionChange)="feedbackSelectionChange($event)"
                >
                    @for (userFeedback of page.items; track userFeedback) {
                        <mat-list-option
                            class="tw-border-t tw-border-solid tw-border-0 tw-border-customborder"
                            [value]="userFeedback"
                            [matTooltip]="userFeedback.description"
                            matTooltipPosition="left"
                        >
                            <div class="tw-flex tw-flex-row tw-justify-between">
                                <div
                                    class="tw-overflow-hidden tw-whitespace-no-wrap tw-text-ellipsis"
                                >
                                    {{ userFeedback.description }}
                                </div>
                                <mat-chip
                                    class="tw-flex tw-ml-4"
                                    [ngClass]="{
                                        'm3-success':
                                            userFeedback.status === 'Processed',
                                        'm3-caution':
                                            userFeedback.newComments > 0,
                                        'm3-primary':
                                            userFeedback.status === 'New' &&
                                            userFeedback.newComments === 0
                                    }"
                                    highlighted
                                >
                                    <div class="tw-min-w-16 tw-text-center">
                                        {{
                                            userFeedback.newComments > 0
                                                ? userFeedback.newComments
                                                : userFeedback.status
                                        }}
                                    </div>
                                </mat-chip>
                            </div>
                        </mat-list-option>
                    }
                </mat-selection-list>
            </mat-card-content>
            <mat-card-actions class="tw-flex tw-justify-end">
                <mat-paginator
                    class="tw-rounded-b-lg tw-bg-transparent"
                    [pageIndex]="pageIndex"
                    [pageSize]="page.countRequested"
                    [length]="page.totalCount"
                    [hidePageSize]="true"
                    (page)="changePage($event)"
                ></mat-paginator>
            </mat-card-actions>
        </mat-card>

        <div
            class="tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-5 tw-min-w-fit"
            *ngIf="selectedUserFeedback !== null"
        >
            <app-edit-user-feedback
                [userFeedback]="selectedUserFeedback"
                [userView]="userView"
            ></app-edit-user-feedback>
        </div>
    </div>
</form>
