import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ROUTES } from '../sidenav/sidenav.component';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NavigationService } from 'src/app/services/navigation.service';
import { Route } from 'src/app/models/route.model';
import { Me } from 'src/app/models/me.model';
import { Permission } from 'src/app/models/permission.model';
import { Modules } from 'src/app/enums/modules.enum';
import { PermissionsService } from 'src/app/services/permission.service';
import { NgFor, NgIf } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppStateService } from '../../services/app-state-service';

@Component({
    selector: 'app-route-search',
    templateUrl: './route-search.component.html',
    styleUrls: ['./route-search.component.scss'],
    standalone: true,
    imports: [
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatListModule,
        NgFor,
        NgIf,
    ],
})
export class RouteSearchComponent implements OnInit {
    formGroup = new FormGroup({
        search: new FormControl<string>(''),
    });

    @Output() close = new EventEmitter();

    approvePermissions = new Map<string, boolean>();
    permissions = new Map<string, boolean>();
    userPermissions: Permission[];
    Modules = Modules;
    userRoutes: Array<Route>;

    constructor(
        private navigationService: NavigationService,
        private appStateService: AppStateService,
        public permissionService: PermissionsService,
    ) {
        this.formGroup.controls.search.valueChanges
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((value: string) => {
                this.filterRoutes(value);
            });
    }

    ngOnInit(): void {
        this.appStateService.me$.subscribe((me: Me) => {
            if (me.id) {
                this.userRoutes = ROUTES.filter((r) =>
                    me.pagePermissions.some((pp) => pp.module == r.permission),
                );

                const homeRoute = ROUTES.find((r) => r.permission == 'Home');
                if (homeRoute) {
                    //give all users home
                    this.userRoutes.unshift(homeRoute);
                }
            }
        });
    }

    filterRoutes(strSearch: string): void {
        if (strSearch != null && strSearch != '') {
            this.userRoutes = [...ROUTES].filter((route) => {
                return route.name
                    .toLowerCase()
                    .includes(strSearch.toLowerCase());
            });
        } else {
            this.userRoutes = [...ROUTES];
        }
    }

    onRouteChangeClick(route: string): void {
        this.navigationService.Route(route);
        this.close.emit();
    }
}
