import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DocumentService } from 'src/app/services/document.service';
import { AddDocumentComponent } from './add-document/add-document.component';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
    MatTreeFlatDataSource,
    MatTreeFlattener,
    MatTreeModule,
} from '@angular/material/tree';
import { Document } from 'src/app/models/document.model';
import { MatMenuTrigger, MatMenuModule } from '@angular/material/menu';
import { AddContainerComponent } from './add-container/add-container.component';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ACTIVE } from 'src/app/constants/statusConstant';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { VisibleDirective } from '../../directives/visible.directive';
import { AppStateService } from 'src/app/services/app-state-service';
import { Me } from 'src/app/models/me.model';
import { Modules } from 'src/app/enums/modules.enum';
import { MatCardModule } from '@angular/material/card';

interface ContainerNode {
    name: string;
    children?: FileNode[];
}

interface FileNode {
    name: string;
    document: Document;
}

interface FlatNode {
    expandable: boolean;
    name: string;
    level: number;
}

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatTooltipModule,
        MatTreeModule,
        MatIconModule,
        MatMenuModule,
        PdfViewerModule,
        VisibleDirective,
        MatCardModule,
    ],
})
export class DocumentsComponent implements OnInit {
    documents: Array<Document>;
    loadingDocuments = false;
    blobContainers: Array<string>;
    blob: Blob;
    docInt8Array: Uint8Array;
    selectedDocument: Document;
    showDocument = false;
    documentBase64: Document;
    selectedNode: FlatNode | ContainerNode | FileNode;
    isTreeCollapsed = false;
    treeCollapseToggle = 'Collapse';
    restricted: boolean = true;

    queryParams: Map<string, unknown> = new Map<string, unknown>([
        ['status', ACTIVE],
        ['count', -1],
        ['offset', 0],
    ]);

    //#region Tree
    private _transformer = (
        node: ContainerNode & { document?: Document },
        level: number,
    ) => {
        const hasChildren = !!node.children && node.children.length > 0;
        const isChild = typeof node['document'] === 'object';

        return {
            expandable: hasChildren || !isChild,
            name: hasChildren
                ? node?.name || ''
                : node['document']?.docName || node.name,
            level: level,
            document: hasChildren ? '' : node['document'],
        };
    };

    treeControl = new FlatTreeControl<FlatNode>(
        (node) => node.level,
        (node) => node.expandable,
    );

    treeFlattener = new MatTreeFlattener(
        this._transformer,
        (node) => node.level,
        (node) => node.expandable,
        (node) => node.children,
    );

    dataSource = new MatTreeFlatDataSource(
        this.treeControl,
        this.treeFlattener,
    );

    hasChild = (_: number, node: FlatNode): boolean => node.expandable;
    //#endregion

    @ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;
    menuTopLeftPosition = { x: '0', y: '0' };

    constructor(
        private documentService: DocumentService,
        private dialog: MatDialog,
        private appStateService: AppStateService,
    ) {
        this.getDocuments();
    }
    ngOnInit(): void {
        this.appStateService.me$.subscribe((me: Me) => {
            if (me.id) {
                this.restricted =
                    me.pagePermissions.filter(
                        (p) =>
                            p.module == Modules.DOCUMENTS.toString() &&
                            p.accessLevel == 'ReadOnly',
                    ).length > 0;
            }
        });
    }

    protected createContainer(): void {
        const dialog = this.dialog.open(AddContainerComponent, {
            disableClose: true,
            data: '',
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.getDocuments();
            }
        });
    }

    protected onRightClick(
        event: MouseEvent,
        node: FlatNode | ContainerNode | FileNode,
    ): void {
        event.preventDefault();

        this.selectedNode = node;
        this.menuTopLeftPosition.x = `${event.clientX}px`;
        this.menuTopLeftPosition.y = `${event.clientY}px`;
        this.matMenuTrigger.menuData = { item: node };

        this.matMenuTrigger.openMenu();
    }

    protected onTreeCollapseChange(): void {
        this.isTreeCollapsed = !this.isTreeCollapsed;
        if (this.isTreeCollapsed) {
            this.treeControl.collapseAll();
        } else {
            this.treeControl.expandAll();
        }
    }

    protected openAddDialog(node: FlatNode | ContainerNode | FileNode): void {
        const dialog = this.dialog.open(AddDocumentComponent, {
            disableClose: true,
            data: node,
        });

        dialog.afterClosed().subscribe((result) => {
            if (result) {
                this.getDocuments();
            }
        });
    }

    private formatContentType(type: string): string {
        const imagesMIMES = ['png', 'jpeg', 'jpg'];
        const applicationMIMES = [
            'pdf',
            'doc',
            'docx',
            'ppt',
            'pptx',
            'xls',
            'xlsx',
        ];

        if (imagesMIMES.includes(type)) {
            return `image/${type}`;
        } else if (applicationMIMES.includes(type)) {
            return `application/${type}`;
        } else {
            return type;
        }
    }

    private dataURItoBlob(dataURI: string, type: string) {
        const byteString = window.atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([int8Array], { type: type });
        this.blob = blob;
        this.docInt8Array = int8Array;
        return blob;
    }

    protected downloadFile(): void {
        const a = document.createElement('a');
        a.href = `data:image/png;base64,${this.documentBase64.toString()}`;
        a.download = `${this.selectedDocument.docName}.pdf`;
        a.click();
    }

    protected viewFile(node: FileNode): void {
        const document = node.document;

        if (document?.id != null) {
            this.documentService
                .download(document.id)
                .subscribe((x: { data: Document }) => {
                    this.documentBase64 = x.data;
                    const blob = this.dataURItoBlob(
                        x.data.toString(),
                        this.formatContentType(document.extension),
                    );
                    if (document.extension.includes('pdf')) {
                        this.selectedDocument = document;
                        this.showDocument = true;
                    } else {
                        const url = URL.createObjectURL(blob);
                        window.open(url, '_blank');
                        this.showDocument = false;
                    }
                });
        }
    }

    protected removeDocument(document: Document): void {
        const dialog = this.dialog.open(ConfirmationDialogComponent, {
            data: 'Are you sure you want to remove this?',
        });
        dialog.afterClosed().subscribe((response: boolean) => {
            if (response === true) {
                if ('document' in this.selectedNode) {
                    this.documentService
                        .delete([`${document.id}/Delete`], null)
                        .subscribe(() => {
                            this.getDocuments();
                        });
                } else {
                    this.documentService
                        .delete(
                            [
                                `DeleteBlobContainer?containerName=${this.selectedNode.name}`,
                            ],
                            null,
                        )
                        .subscribe(() => {
                            this.getDocuments();
                        });
                }
            }
        });
    }

    private getDocuments(offsetRequested?: number) {
        this.loadingDocuments = true;

        if (offsetRequested) {
            this.setQueryParams(offsetRequested);
        } else {
            this.setQueryParams();
        }

        this.documentService
            .get([], this.queryParams)
            .subscribe((page: { items: Document[] }) => {
                this.documents = page.items;
                this.loadingDocuments = false;
                this.getDocumentContainers();
            });
    }

    private getDocumentContainers(): void {
        const files: Array<{
            name: string;
            document: Document;
            container: string;
        }> = this.documents.map((doc: Document) => ({
            name: doc.docName,
            document: doc,
            container:
                doc.fileLocation.split('/')[
                    doc.fileLocation.split('/').length - 2
                ],
        }));

        this.documentService
            .get(['GetBlobContainers'], null)
            .subscribe((resp: { data: string[] }) => {
                this.blobContainers = resp.data;
                const fileNode: Array<ContainerNode> =
                    new Array<ContainerNode>();
                this.blobContainers.forEach((container) => {
                    fileNode.push({
                        name: container,
                        children: files.filter(
                            (x) => x.container === container,
                        ),
                    });
                });
                this.dataSource.data = fileNode;
                this.treeControl.expandAll();
            });
    }

    private setQueryParams(offsetRequested?: number): void {
        if (offsetRequested) {
            this.queryParams.set('offset', offsetRequested);
        } else {
            this.queryParams.set('offset', 0);
        }
        this.queryParams.set('status', ACTIVE);
    }
}
