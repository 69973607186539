<mat-progress-bar
    [appVisible]="loadingUsers"
    mode="indeterminate"
></mat-progress-bar>

<div
    class="tw-grid tw-grid-flow-row md:tw-grid-cols-10 tw-items-start tw-gap-4 tw-m-4"
>
    <mat-card
        class="tw-flex tw-flex-col tw-gap-2 tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-3 lg:tw-col-start-2 2xl:tw-col-start-2 tw-min-w-fit"
    >
        <mat-card-content class="tw-grid tw-grid-flow-row tw-gap-2">
            <div
                [formGroup]="searchForm"
                class="tw-grid tw-grid-rows-1 tw-gap-2 tw-w-full"
            >
                <mat-form-field appearance="fill" subscriptSizing="dynamic">
                    <mat-label>Search Users</mat-label>
                    <input matInput type="text" formControlName="search" />
                </mat-form-field>

                <mat-form-field subscriptSizing="dynamic">
                    <mat-label>Role</mat-label>
                    <mat-select
                        formControlName="roleSearch"
                        (selectionChange)="roleSelectionChange()"
                    >
                        <mat-option value="">Select...</mat-option>
                        <mat-option
                            *ngFor="let role of roleList"
                            [value]="role.id"
                        >
                            {{ role.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="tw-flex tw-justify-between">
                <mat-slide-toggle
                    class="tw-content-center"
                    (change)="toggleActiveInactive($event)"
                    [checked]="userStatus"
                >
                    @if (userStatus) {
                        <ng-container>Active</ng-container>
                    } @else {
                        <ng-container>Inactive</ng-container>
                    }
                </mat-slide-toggle>

                <button mat-raised-button (click)="openAddDialog()">
                    + User
                </button>
            </div>

            <mat-selection-list
                #userSelectionList
                hideSingleSelectionIndicator
                multiple="false"
            >
                @for (user of page.items; track user) {
                    <mat-list-option [value]="user">
                        {{ user.firstName }} {{ user.lastName }}
                    </mat-list-option>
                    <mat-divider></mat-divider>
                }
            </mat-selection-list>
        </mat-card-content>
        <mat-card-actions class="tw-ml-auto">
            <mat-paginator
                class="tw-bg-transparent"
                [pageIndex]="pageIndex"
                [pageSize]="page.countRequested"
                [length]="page.totalCount"
                [hidePageSize]="true"
                (page)="changePage($event)"
            ></mat-paginator>
        </mat-card-actions>
    </mat-card>

    <div class="tw-col-span-5 lg:tw-col-span-4 2xl:tw-col-span-5 tw-min-w-fit">
        <app-edit-user
            [hidden]="!optionSelected"
            [roles]="roleList"
            [levels]="levelsList"
            (updateSuccess)="onUpdateSuccessLoad($event)"
            (loadingUsers)="usersLoadingToggle($event)"
        ></app-edit-user>
    </div>
</div>
