<button
    class="tw-mr-1"
    mat-raised-button
    matTooltip="New Task"
    matTooltipPosition="below"
    [matMenuTriggerFor]="client_menu"
    [disabled]="data.length == 0 || lockedTimesheet"
>
    <mat-icon>add</mat-icon>
    Task
</button>

<button
    [disabled]="data.length == 0 || lockedTimesheet"
    mat-raised-button
    matTooltip="Get Past Weeks Tasks"
    matTooltipPosition="below"
    (click)="getPastTasksEvent.emit()"
>
    <div class="tw-text-center">
        <mat-icon>history</mat-icon>
    </div>
</button>

<mat-menu #client_menu="matMenu">
    <ng-container *ngFor="let clientInfo of data">
        <button mat-menu-item [matMenuTriggerFor]="project_menu">
            {{ clientInfo.clientName }}
        </button>
        <mat-menu #project_menu="matMenu" xPosition="before">
            <ng-container *ngFor="let projectInfo of clientInfo.projects">
                <button mat-menu-item [matMenuTriggerFor]="task_menu">
                    {{ projectInfo.projectName }}
                </button>
                <mat-menu #task_menu="matMenu" xPosition="before">
                    <ng-container *ngFor="let taskInfo of projectInfo.tasks">
                        <button
                            mat-menu-item
                            (click)="taskSelectedEvent.emit(taskInfo)"
                            [matTooltip]="taskInfo.name"
                            [matTooltipPosition]="'left'"
                        >
                            {{ taskInfo.name }}
                        </button>
                    </ng-container>
                </mat-menu>
            </ng-container>
        </mat-menu>
    </ng-container>
</mat-menu>
