<mat-card class="tw-gap-2">
    <mat-card-header class="tw-flex tw-justify-between tw-items-center">
        <mat-card-title>Edit User</mat-card-title>
        <button mat-icon-button (click)="updateSuccess.emit(true)">
            <mat-icon class="tw-m-0 m3-error">close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="userForm" class="tw-grid tw-grid-col tw-gap-2">
            <p *ngIf="failed">Failed to edit users.</p>
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input [readonly]="!user" matInput formControlName="email" />
                <mat-error
                    *ngIf="this.userForm.controls.email.errors?.required"
                >
                    Email is required
                </mat-error>
                <mat-error *ngIf="this.userForm.controls.email.errors?.email">
                    Email must follow pattern name&#64;domain.extension
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>First Name</mat-label>
                <input
                    [readonly]="!user"
                    matInput
                    formControlName="firstName"
                />
                <mat-error
                    *ngIf="this.userForm.controls.firstName.errors?.required"
                >
                    First Name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Last Name</mat-label>
                <input [readonly]="!user" matInput formControlName="lastName" />
                <mat-error
                    *ngIf="this.userForm.controls.lastName.errors?.required"
                >
                    Last Name is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Role</mat-label>
                <mat-select formControlName="roleId">
                    <mat-option *ngFor="let role of roles" [value]="role.id">
                        {{ role.name }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="this.userForm.controls.roleId.errors?.required"
                >
                    Role is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Office Location</mat-label>
                <mat-select formControlName="locationId">
                    <mat-option
                        *ngFor="let location of locations"
                        [value]="location.id"
                    >
                        Located in {{ location.name }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="userForm.controls.locationId.errors?.required"
                >
                    Office Location field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Reports to</mat-label>
                <mat-select [disabled]="!user" formControlName="managerId">
                    <mat-option
                        *ngFor="let manager of managers"
                        [value]="manager.id"
                    >
                        {{ manager.firstName }} {{ manager.lastName }}
                    </mat-option>
                </mat-select>
                <mat-error
                    *ngIf="this.userForm.controls.managerId.errors?.required"
                >
                    Reports to field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Start Date</mat-label>
                <input
                    matInput
                    [matDatepicker]="startDate"
                    formControlName="startDate"
                />
                <mat-datepicker-toggle
                    matSuffix
                    [for]="startDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #startDate></mat-datepicker>
                <mat-error
                    *ngIf="this.userForm.controls.startDate.errors?.required"
                >
                    Start Date field is required
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill">
                <mat-label>Employee Type</mat-label>

                <mat-select formControlName="employeeTypeId">
                    <ng-container *ngFor="let employeeType of employeeTypes">
                        <mat-option
                            *ngIf="
                                employeeType.isActive ||
                                employeeType.id === employeeTypeId
                            "
                            [value]="employeeType.id"
                        >
                            {{ employeeType.name }}
                        </mat-option>
                    </ng-container>
                </mat-select>

                <mat-error
                    *ngIf="userForm.controls.employeeTypeId.errors?.required"
                >
                    Employee Type field is required
                </mat-error>
            </mat-form-field>

            <div class="tw-flex tw-flex-row tw-justify-between">
                <button
                    [disabled]="!user || loading"
                    mat-raised-button
                    class="m3-error"
                    (click)="resetComponent()"
                >
                    Reset
                </button>
                <button
                    [disabled]="!user || loading"
                    mat-raised-button
                    (click)="onFormSave(true)"
                >
                    Save Changes
                </button>
            </div>

            <div>
                <mat-checkbox [disabled]="!user" formControlName="status">
                    Active
                </mat-checkbox>

                <mat-checkbox formControlName="approver">Approver</mat-checkbox>

                <mat-checkbox [disabled]="true" formControlName="is1099">
                    1099 Contractor
                </mat-checkbox>
            </div>

            <mat-divider></mat-divider>

            <div class="tw-flex tw-flex-row tw-justify-between">
                <div class="tw-text-xl">Tasks</div>

                <button mat-raised-button (click)="addTask()">+ Task</button>
            </div>
            <ng-container *ngIf="tasks.data.length > 0">
                <table
                    mat-table
                    class="tw-w-full tw-bg-transparent"
                    [dataSource]="tasks"
                >
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Name</th>
                        <td
                            mat-cell
                            *matCellDef="let task"
                            class="tw-overflow-hidden tw-whitespace-no-wrap tw-text-ellipsis tw-break-all"
                        >
                            {{ task.clientName }} - {{ task.projectName }} -
                            {{ task.name }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="action">
                        <th mat-header-cell *matHeaderCellDef></th>
                        <td
                            mat-cell
                            *matCellDef="let task"
                            class="tw-text-center"
                        >
                            <button
                                mat-raised-button
                                class="m3-error"
                                (click)="removeTask(task)"
                            >
                                Remove
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                    ></tr>
                </table>
            </ng-container>
        </form>
    </mat-card-content>
</mat-card>
