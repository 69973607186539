<form [formGroup]="timesheetForm" class="tw-cursor">
    <div class="tw-text-center tw-grid tw-grid-cols-10 tw-gap-2">
        <div
            [matTooltip]="tooltipText()"
            matTooltipPosition="after"
            class="tw-text-left tw-flex-col tw-min-w-0 tw-justify-center tw-flex tw-flex-auto tw-max-w-96 tw-w-full tw-col-span-2"
        >
            <div
                class="tw-whitespace-nowrap tw-flex-initial tw-text-ellipsis tw-overflow-hidden"
            >
                <span>{{ timesheetForm.controls.clientName.value }}</span>
            </div>

            <mat-divider></mat-divider>

            <div
                class="tw-whitespace-nowrap tw-flex-initial tw-text-ellipsis tw-overflow-hidden"
            >
                <span>{{ timesheetForm.controls.projectName.value }}</span>
            </div>

            <mat-divider></mat-divider>

            <div
                class="tw-whitespace-nowrap tw-flex-initial tw-text-ellipsis tw-overflow-hidden"
            >
                <span>{{ timesheetForm.controls.taskName.value }}</span>
            </div>
        </div>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Mon {{ getDay(0) }}</mat-label>

            <input
                type="number"
                #mondayHours
                (click)="mondayHours.select()"
                matInput
                formControlName="monday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('monday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.mondayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Tues {{ getDay(1) }}</mat-label>

            <input
                type="number"
                #tuesdayHours
                (click)="tuesdayHours.select()"
                matInput
                formControlName="tuesday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('tuesday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.tuesdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Wed {{ getDay(2) }}</mat-label>

            <input
                type="number"
                #wednesdayHours
                (click)="wednesdayHours.select()"
                matInput
                formControlName="wednesday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('wednesday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.wednesdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Thurs {{ getDay(3) }}</mat-label>

            <input
                type="number"
                #thursdayHours
                (click)="thursdayHours.select()"
                matInput
                formControlName="thursday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('thursday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.thursdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Fri {{ getDay(4) }}</mat-label>

            <input
                type="number"
                #fridayHours
                (click)="fridayHours.select()"
                matInput
                formControlName="friday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('friday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.fridayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Sat {{ getDay(5) }}</mat-label>

            <input
                type="number"
                #saturdayHours
                (click)="saturdayHours.select()"
                matInput
                formControlName="saturday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('saturday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.saturdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-min-w-24 tw-pt-5 tw-pointer-events-none tw-opacity-50"
            appearance="outline"
            floatLabel="always"
        >
            <mat-label>Sun {{ getDay(6) }}</mat-label>

            <input
                type="number"
                #sundayHours
                (click)="sundayHours.select()"
                matInput
                formControlName="sunday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('sunday')"
                matTooltip="View Notes for Day"
                class="hover:tw-cursor-pointer"
                [ngClass]="{ hasValue: timesheetForm.value.sundayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>
        </mat-form-field>

        <div class="tw-self-center tw-justify-self-center">
            Task Total
            <mat-divider></mat-divider>
            {{ totalHours }}
        </div>
    </div>
</form>
