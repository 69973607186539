<mat-card>
    <mat-card-header class="tw-flex tw-justify-between tw-items-center tw-mb-2">
        <mat-card-title>Edit Employee Type</mat-card-title>
        <button mat-icon-button (click)="saveCompleted.emit()">
            <mat-icon class="tw-m-0 m3-error">close</mat-icon>
        </button>
    </mat-card-header>
    <mat-card-content>
        <form class="tw-grid tw-gap-2" [formGroup]="employeeTypeForm">
            <p class="tw-bg-red-600" *ngIf="failed">
                Failed to {{ id ? "edit" : "add" }} Employee Type.
            </p>

            <mat-form-field appearance="fill" class="tw-w-full">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" />
            </mat-form-field>

            <mat-checkbox class="tw-w-4/5" formControlName="showInUtilization">
                Show in Utilization Report
            </mat-checkbox>
            <mat-checkbox class="tw-w-4/5" formControlName="isActive">
                Active
            </mat-checkbox>

            <div
                class="tw-flex tw-flex-row tw-w-full tw-gap-2 tw-justify-between"
            >
                <button
                    mat-raised-button
                    class="m3-error"
                    (click)="onClickReset()"
                >
                    Reset
                </button>
                <button mat-raised-button (click)="saveEmployeeType()">
                    Save Changes
                </button>
            </div>
        </form>
    </mat-card-content>
</mat-card>
