import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Permission } from 'src/app/models/permission.model';
import { Role } from 'src/app/models/role.model';
import { PermissionsService } from 'src/app/services/permission.service';
import { RolesService } from 'src/app/services/roles.service';
import { AddRoleDialog } from './add-role-dialog/add-role-dialog.component';
import { UpdateUserRoleDialog } from './update-user-role-dialog/update-user-role-dialog.component';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
    MatPaginator,
    MatPaginatorModule,
    PageEvent,
} from '@angular/material/paginator';
import { Me } from 'src/app/models/me.model';
import { MeService } from 'src/app/services/me.service';
import { UserService } from 'src/app/services/user.service';
import { SEARCH_DEBOUNCE_MS } from 'src/app/constants/searchConstants';
import { Page } from 'src/app/models/page.model';
import { MatListModule, MatSelectionListChange } from '@angular/material/list';
import { User } from 'src/app/models/User';
import { ACTIVE, INACTIVE } from 'src/app/constants/statusConstant';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppStateService } from '../../services/app-state-service';
import { VisibleDirective } from '../../directives/visible.directive';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';

export interface dialogRefResponse {
    continueUpdate: boolean;
}

@Component({
    selector: 'app-roles-permissions',
    templateUrl: './roles-permissions.component.html',
    styleUrls: ['./roles-permissions.component.scss'],
    standalone: true,
    imports: [
        MatTooltipModule,
        NgIf,
        MatButtonModule,
        MatSlideToggleModule,
        MatProgressBarModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        MatListModule,
        NgFor,
        MatPaginatorModule,
        MatTableModule,
        MatCheckboxModule,
        VisibleDirective,
        NgClass,
        MatCardModule,
        MatIconModule,
    ],
})
export class RolesPermissionsComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    checkAll = false;
    roles: Role[] = [];
    permissions: Permission[] = [];
    modules: string[] = [];
    selectedRolePermissions: Permission[] = [];
    selectedRole: Role;
    isRoleSelected = false;
    displayedColumns: string[] = ['Module', 'Full', 'Restricted'];
    loadingPermissions = false;
    loadingRoles = false;
    disableSaveChanges = true;
    isViewingActiveRoles = true;
    roleNameFormControl = new FormControl<string>('');
    restrictedModules: string[] = [];

    pageIndex = 0;
    queryParams: Map<string, unknown> = new Map<string, unknown>();
    fullLength: number;
    countSize: number;

    constructor(
        private rolesService: RolesService,
        private permissionService: PermissionsService,
        public dialog: MatDialog,
        private appStateService: AppStateService,
        private meService: MeService,
        private userService: UserService,
    ) {
        this.selectedRole = null;
        this.isRoleSelected = false;

        this.roleNameFormControl.valueChanges
            .pipe(debounceTime(SEARCH_DEBOUNCE_MS), distinctUntilChanged())
            .subscribe(() => {
                this.filterRoles();
            });
    }

    ngOnInit(): void {
        this.getAllRoles();
        this.getAllPermissions();
    }

    getAllRoles(): void {
        this.roles = [];
        this.loadingRoles = true;

        this.queryParams.set('count', 10);

        if (this.pageIndex) {
            this.queryParams.set('offset', this.pageIndex * 10);
        } else {
            this.queryParams.set('offset', 0);
        }

        if (this.isViewingActiveRoles) {
            this.queryParams.set('status', ACTIVE);
        } else {
            this.queryParams.set('status', INACTIVE);
        }

        this.rolesService
            .get([], this.queryParams)
            .subscribe((roles: Page<Role>) => {
                this.roles = roles.items;
                this.countSize = 10;
                this.fullLength = roles.totalCount;
                this.loadingRoles = false;
            });
    }

    getAllPermissions(): void {
        this.loadingPermissions = true;
        this.permissions = [];
        const queryParams = new Map([['count', -1]]);

        this.permissionService
            .get<Page<Permission>>([], queryParams)
            .subscribe({
                next: (response: Page<Permission>) => {
                    this.permissions = response.items;
                    this.createArrayOfModuleNames();
                    this.getPagesWithRestrictedAccess();
                    this.loadingPermissions = false;
                },
                error: () => {
                    this.loadingPermissions = false;
                },
            });
    }

    clearSearchValue(): void {
        this.roleNameFormControl.reset();
    }

    toggleRolesList(): void {
        this.clearSearchValue();
        this.isViewingActiveRoles = !this.isViewingActiveRoles; //Needs to happen before getAllRoles
        this.paginator.firstPage();
        this.changePageIndex(0);
        this.getAllPermissions();

        this.selectedRolePermissions = [];
        this.selectedRole = null;
        this.isRoleSelected = false;
        this.disableSaveChanges = true;
    }

    createArrayOfModuleNames(): void {
        const modules: string[] = this.permissions.map(
            (entry) => entry['module'],
        );
        const set = new Set(modules);
        this.modules = Array.from(set);
    }

    getPagesWithRestrictedAccess(): void {
        const moduleMap = new Map<string, string[]>();

        this.permissions.forEach((permission) => {
            if (!moduleMap.has(permission.module)) {
                moduleMap.set(permission.module, []);
            }
            moduleMap.get(permission.module)?.push(permission.accessLevel);
        });

        // Find modules with multiple access types
        const restricted = Array.from(moduleMap.entries())
            .filter(([module, accessTypes]) => new Set(accessTypes).size > 1)
            .map(([module]) => module);

        this.restrictedModules = restricted;
    }

    createSelectedRolePermissions(): void {
        this.selectedRolePermissions = [];
        this.disableSaveChanges = true;

        this.modules.forEach((module) => {
            const permission: Permission = new Permission();
            permission.module = module;

            const perm = this.selectedRole.pagePermissions.filter(
                (selectedRolePermission) => {
                    return selectedRolePermission.module === module;
                },
            )[0];
            if (perm) {
                permission.accessLevel = perm.accessLevel;
            }

            this.selectedRolePermissions.push(permission);
        });
    }

    onSelectRole(event: MatSelectionListChange | Role): void {
        let role: Role;

        if (event instanceof Role) {
            role = event;
        } else {
            role = event.options[0].value as Role;
        }

        this.disableSaveChanges = true;
        this.loadingPermissions = true;
        this.rolesService.get([role.id]).subscribe(
            (foundRole: Role) => {
                //These are in a specific order due to methods needing certain variables set up
                this.selectedRole = foundRole;
                this.createArrayOfModuleNames();
                this.isRoleSelected = true;
                this.selectedRole = foundRole;
                this.createSelectedRolePermissions();
                this.loadingPermissions = false;
            },
            () => {
                this.loadingPermissions = false;
            },
        );
    }

    filterRoles(): void {
        this.loadingRoles = true;
        const map = new Map<string, string>();
        map.set('name', this?.roleNameFormControl?.value ?? '');

        this.rolesService.get([], map).subscribe((roles: Page<Role>) => {
            this.selectedRole = null;
            this.isRoleSelected = false;

            if (this.isViewingActiveRoles) {
                this.roles = roles.items.filter(
                    (role) =>
                        role.status.toLowerCase() === ACTIVE.toLowerCase(),
                );
            } else {
                this.roles = roles.items.filter(
                    (role) =>
                        role.status.toLowerCase() === INACTIVE.toLowerCase(),
                );
            }

            this.loadingRoles = false;
        });
    }

    savePermissionChanges(): void {
        const updatePerms: Permission[] = this.selectedRolePermissions.filter(
            (p) => p.accessLevel != null,
        );

        updatePerms.forEach(
            (perm) =>
                (perm.id = this.permissions.filter(
                    (permission) => permission.module == perm.module,
                )[0].id),
        );

        this.rolesService
            .put([this.selectedRole.id], {
                pagePermissions: updatePerms,
            })
            .subscribe(() => {
                this.selectedRole = null;
                this.isRoleSelected = false;
                this.getAllRoles();
                this.getAllPermissions();
                this.disableSaveChanges = true;
                this.meService.get([]).subscribe((me: Me) => {
                    this.appStateService.me$.next(me);
                });
            });
    }

    changeActiveStatus(): void {
        const changedRole = this.selectedRole;

        if (changedRole.status.toUpperCase() === ACTIVE) {
            const queryParams: Map<string, unknown> = new Map<string, unknown>([
                ['status', ACTIVE],
                ['count', 10],
                ['offset', 0],
                ['roleId', this.selectedRole.id],
            ]);

            this.userService
                .get([], queryParams)
                .subscribe((result: Page<User>) => {
                    if (result.totalCount > 0) {
                        const dialogRef = this.dialog.open(
                            UpdateUserRoleDialog,
                            {
                                data: this.selectedRole.id,
                            },
                        );

                        dialogRef
                            .afterClosed()
                            .subscribe((response: dialogRefResponse) => {
                                if (response.continueUpdate) {
                                    changedRole.status = INACTIVE;

                                    this.rolesService
                                        .put(
                                            [this.selectedRole.id],
                                            changedRole,
                                        )
                                        .subscribe(() => {
                                            this.getAllRoles();
                                            this.getAllPermissions();

                                            this.selectedRolePermissions = [];
                                            this.selectedRole = null;
                                            this.isRoleSelected = false;
                                            this.disableSaveChanges = true;
                                        });
                                }
                            });
                    } else {
                        changedRole.status = INACTIVE;

                        this.rolesService
                            .put([this.selectedRole.id], changedRole)
                            .subscribe(() => {
                                this.getAllRoles();
                                this.getAllPermissions();

                                this.selectedRolePermissions = [];
                                this.selectedRole = null;
                                this.isRoleSelected = false;
                                this.disableSaveChanges = true;
                            });
                    }
                });
        } else {
            changedRole.status = ACTIVE;

            this.rolesService
                .put([this.selectedRole.id], changedRole)
                .subscribe(() => {
                    this.getAllRoles();
                    this.getAllPermissions();

                    this.selectedRolePermissions = [];
                    this.selectedRole = null;
                    this.isRoleSelected = false;
                    this.disableSaveChanges = true;
                });
        }
    }

    openDialog(): void {
        const dialogRef = this.dialog.open(AddRoleDialog);

        dialogRef.afterClosed().subscribe((response: string) => {
            if (response != null) {
                this.rolesService.get([response]).subscribe((role: Role) => {
                    this.selectedRole = role;
                    this.isRoleSelected = true;
                    this.onSelectRole(role);
                    this.getAllRoles();
                    this.isViewingActiveRoles = true;
                });
            }
        });
    }

    changePage(event: PageEvent): void {
        this.changePageIndex(event.pageIndex);
    }

    changePageIndex(index: number): void {
        this.pageIndex = index;
        this.getAllRoles();
    }

    selectUnselectAllPermissions(): void {
        if (this.selectedRole) {
            this.selectedRolePermissions.forEach(
                (p) => (p.accessLevel = !this.checkAll ? 'Admin' : null),
            );
        }

        this.checkAll = !this.checkAll;
        this.disableSaveChanges = false;
    }
}
