import { Injectable } from '@angular/core';
import { Permission } from '../models/permission.model';
import { AbstractService } from './abstract-service.service';

@Injectable({
    providedIn: 'root',
})
export class PermissionsService extends AbstractService {
    path = '/PagePermission';

    permissionsHas(module: string, permissions: Permission[]): boolean {
        let permissionToCheck: Permission;

        if (permissions) {
            permissions.forEach((permission) => {
                if (permission.module === module) {
                    permissionToCheck = permission;
                }
            });

            if (permissionToCheck) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    }
}
