<mat-card>
    <mat-card-content class="tw-flex tw-flex-col tw-gap-2 tw-text-center">
        <div
            class="tw-grid xl:tw-grid-flow-row tw-grid-cols-2 xl:tw-grid-cols-3 tw-justify-between tw-w-full"
        >
            <div class="tw-flex tw-justify-start">
                <button
                    mat-button
                    matTooltip="Previous Week"
                    (click)="onClickPreviousWeek()"
                >
                    <mat-icon class="tw-m-0">keyboard_arrow_left</mat-icon>
                </button>

                <mat-form-field
                    class="tw-min-w-fit"
                    [formGroup]="dateFormGroup"
                    appearance="outline"
                >
                    <mat-label>{{ weekIdentifier }}</mat-label>
                    <mat-date-range-input
                        [rangePicker]="picker"
                        (click)="picker.open()"
                    >
                        <input matStartDate formControlName="beginningOfWeek" />
                        <input matEndDate formControlName="endOfWeek" />
                    </mat-date-range-input>
                    <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                    ></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </mat-form-field>

                <button
                    mat-button
                    matTooltip="Next Week"
                    (click)="onClickNextWeek()"
                >
                    <mat-icon class="tw-m-0">keyboard_arrow_right</mat-icon>
                </button>
            </div>

            <div
                class="tw-order-last xl:tw-order-2 tw-col-start-2 tw-w-fit tw-justify-self-end xl:tw-justify-self-center"
            >
                <mat-list class="tw-grid tw-grid-cols-3 tw-gap-2 tw-p-0">
                    <mat-list-item>
                        <div matListItemTitle>Saved</div>
                        <div matListItemLine>{{ weekHours.saved }}</div>
                    </mat-list-item>
                    <mat-list-item>
                        <div matListItemTitle>Submitted</div>
                        <div matListItemLine>{{ weekHours.submitted }}</div>
                    </mat-list-item>
                    <mat-list-item>
                        <div matListItemTitle>Locked</div>
                        <div matListItemLine>{{ weekHours.locked }}</div>
                    </mat-list-item>
                </mat-list>
            </div>

            <div
                class="tw-flex tw-gap-2 xl:tw-order-3 tw-order-2 tw-justify-end"
            >
                <button
                    mat-raised-button
                    matTooltip="Save All Timesheets"
                    type="button"
                    (click)="saveTimesheetsEvent.emit()"
                >
                    Save All
                </button>

                <button
                    mat-raised-button
                    matTooltip="Submit All Timesheets"
                    type="button"
                    (click)="submitTimesheetsEvent.emit()"
                >
                    Submit All
                </button>

                <button
                    mat-raised-button
                    matTooltip="Edit Active Task"
                    type="button"
                    (click)="editTaskEvent.emit()"
                >
                    Edit Tasks
                </button>
            </div>
        </div>
    </mat-card-content>
</mat-card>
