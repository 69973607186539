<div class="tw-overflow-hidden tw-w-full tw-mt-4">
    <div class="tw-flex tw-justify-center">
        <div class="tw-grid tw-grid-row-1 tw-gap-2 md:tw-w-1/2 tw-w-4/5">
            <div
                class="tw-size-full tw-rounded-[50%] tw-flex tw-justify-center"
            >
                <img
                    class="tw-flex tw-h-[300px] tw-w-[300px] tw-rounded-[50%]"
                    [src]="currentProfileImage"
                />
            </div>
            <div class="tw-flex tw-justify-center">
                <button mat-raised-button (click)="openProfileImageUpdate()">
                    <mat-icon class="select-file-icon">photo_camera</mat-icon>
                    Choose Image
                </button>
            </div>
        </div>
    </div>
    <div class="tw-m-4">Theme</div>
    <div
        [formGroup]="userProfileForm"
        class="tw-flex tw-flex-row tw-justify-center"
    >
        <mat-radio-group
            formControlName="theme"
            class="tw-flex tw-flex-col tw-justify-center tw-gap-3"
        >
            <mat-radio-button
                *ngFor="let option of themeOptions"
                [value]="option"
            >
                {{ option }}
            </mat-radio-button>
            <div class="tw-mb-4">
                <button mat-raised-button (click)="saveTheme()">
                    Update Theme
                </button>
            </div>
        </mat-radio-group>
    </div>
</div>
