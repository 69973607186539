<mat-progress-bar
    [appVisible]="loadingReport"
    mode="indeterminate"
></mat-progress-bar>
<div class="tw-grid tw-grid-cols-10 tw-gap-2 tw-my-4">
    <mat-card class="tw-col-start-2 tw-col-span-8">
        <mat-card-content class="tw-flex tw-flex-row">
            <form
                class="tw-flex tw-flex-row tw-gap-3"
                [formGroup]="filterForm"
                autocomplete="off"
            >
                <mat-form-field>
                    <mat-label>Search by name</mat-label>
                    <input
                        matInput
                        type="text"
                        formControlName="name"
                        (input)="filterChange()"
                    />
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Client</mat-label>

                    <mat-select
                        formControlName="clientId"
                        (selectionChange)="filterChange()"
                    >
                        <mat-option value="">View All</mat-option>
                        <mat-option
                            *ngFor="let client of clients"
                            [value]="client.id"
                        >
                            {{ client.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </form>

            <div
                class="tw-flex tw-flex-row tw-gap-2 tw-ml-auto tw-items-center"
            >
                <button
                    mat-raised-button
                    matTooltip="Clear Filters"
                    (click)="clearFilters()"
                >
                    Clear Filters
                </button>

                <button
                    mat-raised-button
                    matTooltip="Create Work Order"
                    (click)="openWorkOrder(true)"
                >
                    Create Work Order
                </button>
            </div>
        </mat-card-content>
    </mat-card>

    <mat-card class="tw-col-start-2 tw-col-span-8">
        <mat-card-content>
            <table
                mat-table
                [dataSource]="dataSource"
                matSort
                class="tw-bg-transparent tw-w-full"
            >
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Work Order Name
                    </th>
                    <td
                        mat-cell
                        *matCellDef="let workOrder"
                        matTooltip="{{ workOrder.name }}"
                        matTooltipPosition="left"
                    >
                        {{ workOrder.name | slice: 0 : 50
                        }}{{ workOrder.name.length > 30 ? "..." : "" }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="clientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Client Name
                    </th>
                    <td mat-cell *matCellDef="let workOrder">
                        {{ workOrder.clientName }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="createDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>
                        Date Created
                    </th>
                    <td mat-cell *matCellDef="let workOrder">
                        {{ workOrder.createDate | date: "MM/dd/yyyy" }}
                    </td>
                </ng-container>

                <ng-container matColumnDef="open">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let workOrder">
                        <button
                            mat-icon-button
                            (click)="openWorkOrder(false, workOrder)"
                        >
                            <mat-icon>launch</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <ng-container matColumnDef="new">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let workOrder">
                        <button
                            mat-icon-button
                            (click)="openWorkOrder(true, workOrder)"
                            matTooltip="Clone Template"
                            matTooltipPosition="right"
                        >
                            <mat-icon>add_circle_outline</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></tr>
            </table>
        </mat-card-content>
        <mat-card-actions class="tw-flex tw-justify-end">
            <mat-paginator
                class="tw-bg-transparent"
                [pageIndex]="pageIndex"
                [pageSize]="page.countRequested"
                [length]="page.totalCount"
                [hidePageSize]="true"
                (page)="changePage($event)"
            ></mat-paginator>
        </mat-card-actions>
    </mat-card>
</div>
