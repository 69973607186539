<form
    [formGroup]="changePasswordForm"
    class="tw-flex tw-flex-row tw-m-4 tw-justify-center"
>
    <div class="tw-flex tw-flex-col tw-gap-2 tw-w-1/2">
        <mat-form-field appearance="outline">
            <mat-label>Current Password</mat-label>
            <input
                matInput
                formControlName="currentPassword"
                [type]="currentPasswordHide ? 'password' : 'text'"
                required
            />
            <button
                mat-icon-button
                matSuffix
                (click)="currentPasswordHide = !currentPasswordHide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="currentPasswordHide"
            >
                <mat-icon>
                    {{ currentPasswordHide ? "visibility_off" : "visibility" }}
                </mat-icon>
            </button>
            <mat-error
                *ngIf="
                    changePasswordForm.controls.currentPassword.hasError(
                        'required'
                    )
                "
            >
                Current Password is required field
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>New Password</mat-label>
            <input
                matInput
                formControlName="password"
                [type]="newPasswordHide ? 'password' : 'text'"
                required
            />
            <button
                mat-icon-button
                matSuffix
                (click)="newPasswordHide = !newPasswordHide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="newPasswordHide"
            >
                <mat-icon>
                    {{ newPasswordHide ? "visibility_off" : "visibility" }}
                </mat-icon>
            </button>
            <mat-error
                *ngIf="
                    changePasswordForm.controls.password.hasError('required')
                "
            >
                Password is required field
            </mat-error>
            <mat-error
                *ngIf="changePasswordForm.controls.password.hasError('pattern')"
            >
                Password must be eight characters, contain one capital, one
                lowercase, one number, and one symbol (!&#64;#$%^&*-?)
            </mat-error>
            <mat-error
                *ngIf="
                    changePasswordForm.controls.password.hasError(
                        'passwordMatch'
                    )
                "
            >
                New Password and Confirm New Password fields must match
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Confirm New Password</mat-label>
            <input
                matInput
                formControlName="confirmPassword"
                [type]="confirmPasswordHide ? 'password' : 'text'"
                required
            />
            <button
                mat-icon-button
                matSuffix
                (click)="confirmPasswordHide = !confirmPasswordHide"
                [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="confirmPasswordHide"
            >
                <mat-icon>
                    {{ confirmPasswordHide ? "visibility_off" : "visibility" }}
                </mat-icon>
            </button>
            <mat-error
                *ngIf="
                    changePasswordForm.controls.confirmPassword.hasError(
                        'required'
                    )
                "
            >
                Confirm Password is required field
            </mat-error>
        </mat-form-field>
        <div class="tw-flex tw-justify-center">
            <button
                mat-raised-button
                class="tw-w-1/4"
                type="submit"
                [disabled]="!changePasswordForm.valid"
                (click)="onSubmitClicked()"
            >
                Submit
            </button>
        </div>
    </div>
</form>
