export enum Modules {
    HOME = 'Home',
    CLIENTS = 'Clients',
    USERFEEDBACK = 'UserFeedback',
    WORKORDERS = 'WorkOrders',
    OPENWORKORDER = 'OpenWorkOrder',
    DOCUMENTS = 'Documents',
    PERMISSIONS = 'Permissions',
    PROJECTS = 'Projects',
    ROLES = 'Roles',
    TASKS = 'Tasks',
    TIMESHEETS = 'Timesheets',
    CONTRACTORTIMESHEETS = 'ContractorTimesheets',
    ADMINTIMESHEETS = 'AdminTimesheets',
    USERS = 'Users',
    PTOREQUEST = 'Ptorequest',
    PTOACCRUALRATES = 'PtoAccrualRates',
    PTODAYS = 'PtoDaysHours',
    LOCATIONS = 'Locations',
    REPORTS = 'Reports',
    EMPLOYEEDIRECTORY = 'EmployeeDirectory',
    EMPLOYEETYPES = 'EmployeeTypes',
    CONFIGURATION = 'Configuration',
    CALENDAR = 'Calendar',
    SUBMISSIONS = 'Submissions',
    MYFEEDBACK = 'MyFeedback',
}
