import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Me } from './models/me.model';
import { Permission } from './models/permission.model';
import { PermissionsService } from './services/permission.service';
import { AppStateService } from './services/app-state-service';

@Injectable({
    providedIn: 'root',
})
export class PermissionGuard {
    constructor(
        private router: Router,
        private permissionsService: PermissionsService,
        private appStateService: AppStateService,
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        let permissions: Permission[];

        this.appStateService.me$.subscribe((me: Me) => {
            if (me.userLoginId) {
                window.localStorage.setItem('ME', JSON.stringify(me));
                permissions = me.pagePermissions;
            } else {
                const me = window.localStorage.getItem('ME');
                if (me) {
                    permissions = (JSON.parse(me) as Me).pagePermissions;
                }
            }
        });

        const canView = this.permissionsService.permissionsHas(
            route.data.module as string,
            permissions,
        );

        if (canView) {
            return true;
        } else {
            void this.router.navigate(['login']);
            return false;
        }
    }
}
