<form
    [formGroup]="timesheetForm"
    [class]="timesheetForm.controls.locked.value || readOnly ? 'tw-cursor' : ''"
>
    <div
        class="tw-text-center tw-grid tw-grid-cols-8 lg:tw-grid-cols-10 tw-gap-2"
    >
        <div
            [matTooltip]="tooltipText()"
            matTooltipPosition="after"
            class="tw-flex lg:tw-flex-col tw-gap-2 lg:tw-gap-0 tw-flex-row tw-text-left tw-min-w-0 lg:tw-justify-center lg:tw-max-w-96 tw-w-full tw-col-span-8 lg:tw-col-span-2"
        >
            <div
                class="tw-whitespace-nowrap tw-flex-initial tw-text-ellipsis tw-overflow-hidden"
            >
                <span>{{ timesheetForm.controls.clientName.value }}</span>
            </div>
            <mat-divider class="tw-hidden lg:tw-block"></mat-divider>
            <div
                class="tw-whitespace-nowrap tw-flex-initial tw-text-ellipsis tw-overflow-hidden"
            >
                <span>{{ timesheetForm.controls.projectName.value }}</span>
            </div>
            <mat-divider class="tw-hidden lg:tw-block"></mat-divider>
            <div
                class="tw-whitespace-nowrap tw-flex-initial tw-text-ellipsis tw-overflow-hidden"
            >
                <span>{{ timesheetForm.controls.taskName.value }}</span>
            </div>
        </div>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Mon {{ getDay(0) }}</mat-label>

            <input
                type="number"
                #mondayHours
                (click)="mondayHours.select()"
                matInput
                formControlName="monday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('monday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.mondayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error *ngIf="timesheetForm.controls.monday.errors?.oneDecimal">
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.monday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.monday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Tues {{ getDay(1) }}</mat-label>

            <input
                type="number"
                #tuesdayHours
                (click)="tuesdayHours.select()"
                matInput
                formControlName="tuesday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('tuesday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.tuesdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error
                *ngIf="timesheetForm.controls.tuesday.errors?.oneDecimal"
            >
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.tuesday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.tuesday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Wed {{ getDay(2) }}</mat-label>

            <input
                type="number"
                #wednesdayHours
                (click)="wednesdayHours.select()"
                matInput
                formControlName="wednesday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('wednesday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.wednesdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error
                *ngIf="timesheetForm.controls.wednesday.errors?.oneDecimal"
            >
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.wednesday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.wednesday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Thurs {{ getDay(3) }}</mat-label>

            <input
                type="number"
                #thursdayHours
                (click)="thursdayHours.select()"
                matInput
                formControlName="thursday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('thursday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.thursdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error
                *ngIf="timesheetForm.controls.thursday.errors?.oneDecimal"
            >
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.thursday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.thursday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Fri {{ getDay(4) }}</mat-label>

            <input
                type="number"
                #fridayHours
                (click)="fridayHours.select()"
                matInput
                formControlName="friday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('friday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.fridayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error *ngIf="timesheetForm.controls.friday.errors?.oneDecimal">
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.friday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.friday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Sat {{ getDay(5) }}</mat-label>

            <input
                type="number"
                #saturdayHours
                (click)="saturdayHours.select()"
                matInput
                formControlName="saturday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('saturday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.saturdayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error
                *ngIf="timesheetForm.controls.saturday.errors?.oneDecimal"
            >
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.saturday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.saturday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <mat-form-field
            class="tw-text-xs tw-flex-1 tw-pt-5"
            appearance="outline"
            [class]="
                timesheetForm.controls.locked.value || readOnly
                    ? 'tw-pointer-events-none tw-opacity-50'
                    : ''
            "
            floatLabel="always"
        >
            <mat-label>Sun {{ getDay(6) }}</mat-label>

            <input
                type="number"
                #sundayHours
                (click)="sundayHours.select()"
                matInput
                formControlName="sunday"
                onkeydown="return event.key !== 'e' && event.key !== '-' && event.key !== '+'"
            />

            <mat-icon
                (click)="editNoteForDay('sunday')"
                [matTooltip]="
                    timesheetForm.controls.locked.value || readOnly
                        ? 'View Notes for Day'
                        : 'Edit Notes for Day'
                "
                class="hover:tw-cursor-pointer !tw-p-0"
                [ngClass]="{ hasValue: timesheetForm.value.sundayNotes }"
                matSuffix
            >
                note_add
            </mat-icon>

            <mat-error *ngIf="timesheetForm.controls.sunday.errors?.oneDecimal">
                {{ errorText.oneDecimal }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.sunday.errors?.min">
                {{ errorText.minimumValue }}
            </mat-error>

            <mat-error *ngIf="timesheetForm.controls.sunday.errors?.max">
                {{ errorText.maximumValue }}
            </mat-error>
        </mat-form-field>

        <div class="tw-flex">
            <div class="tw-self-center tw-justify-self-center">
                Task Total
                <mat-divider></mat-divider>
                {{ totalHours }}
            </div>
            <div
                *ngIf="!(timesheetForm.controls.locked.value || readOnly)"
                class="tw-items-center tw-flex tw-justify-center"
            >
                <button
                    mat-icon-button
                    *ngIf="!(timesheetForm.controls.locked.value || readOnly)"
                    (click)="onClickRemoveEvent.emit(this.timesheetForm)"
                    matTooltip="Remove Timesheet"
                >
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</form>
