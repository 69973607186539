<mat-progress-bar
    mode="indeterminate"
    [appVisible]="loading"
></mat-progress-bar>
<div
    [appVisible]="message"
    class="tw-w-full tw-flex tw-bg-m3ErrorContainer tw-text-m3OnErrorContainer tw-h-10 tw-text-xl tw-items-center tw-justify-center"
>
    {{ message }}
</div>

<div
    class="tw-grid tw-grid-cols-10 tw-items-center tw-place-self-center tw-w-[99%] 2xl:tw-w-full tw-gap-4"
>
    <div class="tw-col-span-10 2xl:tw-col-start-2 2xl:tw-col-span-8">
        <app-timesheet-controls
            [weekHours]="weekHours"
            (updateDateEvent)="updateDate($event)"
            (saveTimesheetsEvent)="saveTimesheets()"
            (submitTimesheetsEvent)="submitTimesheets()"
            (editTaskEvent)="openEditTaskDialog()"
        ></app-timesheet-controls>
    </div>

    <div class="tw-col-span-10 2xl:tw-col-start-2 2xl:tw-col-span-8">
        <div class="tw-flex tw-justify-end">
            <app-nested-select
                [lockedTimesheet]="lockedTimesheet"
                [timesheetForms]="timesheetForms"
                [lastSelectedDate]="lastSelectedDate"
                (taskSelectedEvent)="createTimesheet($event)"
                (getPastTasksEvent)="getPastTasks()"
            ></app-nested-select>
        </div>
    </div>

    <div
        class="tw-col-span-10 2xl:tw-col-start-2 2xl:tw-col-span-8 tw-grid tw-grid-cols-1 tw-gap-2 tw-min-w-min"
    >
        <ng-container *ngFor="let timesheetForm of timesheetForms">
            <mat-card>
                <mat-card-content>
                    <app-timesheet-item
                        class="tw-w-full"
                        [weekHours]="weekHours"
                        [timesheetForm]="timesheetForm"
                        (onClickRemoveEvent)="openTimesheetRemoveDialog($event)"
                        (updateTotalsEvent)="getTotals()"
                        (unsavedHoursEvent)="setMessage()"
                        [timesheetDates]="lastSelectedDate"
                    ></app-timesheet-item>
                </mat-card-content>
            </mat-card>
        </ng-container>

        <ng-container *ngIf="timesheetForms?.length > 0">
            <app-timesheet-totals
                [timesheetForms]="timesheetForms"
                [weeklyTotalsFormGroup]="weeklyTotalsFormGroup"
            ></app-timesheet-totals>
        </ng-container>
    </div>
</div>
